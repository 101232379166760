import React from 'react';

const UserProfile = () =>{
    return (
        <section>
            UserProfile
        </section>
    )
};

export default UserProfile;