import React from 'react';

export const useInterval = (callback, timeout, signature) => {
    const [timestamp, setTimestamp] = React.useState(Date.now());

    React.useEffect(() => {
        const interval = setInterval(() => {
            let currentTimestamp = Date.now();
            let elapsed = currentTimestamp - timestamp;
            if(elapsed >= timeout){
                callback();
                setTimestamp(currentTimestamp);
            }
        }, 10);
        return () => clearInterval(interval);
    }, [timestamp, ...signature]);
};
