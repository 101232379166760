import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import './Header.scss';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const preventDefault = event => event.preventDefault();

function Header({ loading, toolbar, basedPlanification, onSelectMenu })
{
    const history = useHistory();

    return (
        <Toolbar className="header">
            <IconButton
                edge="start"
                //className={classes.menuButton}
                color="inherit"
                aria-label="go back"
                onClick={() => history.goBack()}
            >
                <ArrowBackIcon />
            </IconButton>
            <div className="contentc">
                <div className="titles">
                    <h1>Crear Planificaci&oacute;n</h1>
                    <span className="type">
                        {(basedPlanification > 0)?(<>Basada en Planificaci&oacute;n <span className="plan-id">#{basedPlanification}</span></>):'Original'}
                    </span>
                </div>
                <div className="menu">
                    <button
                        disabled={loading}
                        onMouseDown={preventDefault}
                        onClick={() => onSelectMenu('save')}
                        className={clsx((toolbar == 'save') && 'selected')}
                    >Guardar</button>
                    <button
                        disabled={loading}
                        onMouseDown={preventDefault}
                        onClick={() => onSelectMenu('dates')} 
                        className={clsx((toolbar == 'dates') && 'selected')}
                    >Fechas</button>
                    <button
                        disabled={loading}
                        onMouseDown={preventDefault}
                        onClick={() => onSelectMenu('rows')}
                        className={clsx((toolbar == 'rows') && 'selected')}
                    >Filas</button>
                </div>
            </div>
            {/* <div className={classes.appbarLogo}>
                <img src={logo} draggable="false" width="100" />
            </div>
            <Button color="inherit">Planta Pellets</Button>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
            </div>
            <div className={classes.sectionMobile}>
                <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                >
                    <MoreIcon />
                </IconButton>
            </div> */}
        </Toolbar>
    )
};

Header.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSelectMenu: PropTypes.func.isRequired,
    basedPlanification: PropTypes.number.isRequired,
    toolbar: PropTypes.string.isRequired,
};

export default Header;