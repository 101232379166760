import { fade, makeStyles } from '@material-ui/core/styles';

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
	layoutContainer: {
		paddingTop: theme.spacing(8),
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		userSelect: 'none',
	},
	drawer: {
		width: DRAWER_WIDTH,
		flexShrink: 0,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	drawerPaper: {
		width: DRAWER_WIDTH,
		paddingTop: 60,
	},
	content: {
		paddingTop: theme.spacing(9),
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		display: 'block',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	menuIcon: {
		minWidth: 40,
	},
	navMenuButton: {
		fontFamily: 'inherit',
		fontWeight: 'normal',
		textTransform: 'none',
		opacity: 0.9,
	},
	navMenuButtonSelected: {
		fontWeight: 'bold',
		opacity: 1,
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	logo: {
		padding: theme.spacing(0.5),
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
	navMenu: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	appbarLogo: {
		padding: theme.spacing(0, 2, 0, 0),
		'& img': {
			display: 'block',
		}
	},
	selectLocation: {
		height: theme.spacing(4),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		border: 0,
		backgroundColor: 'rgba(0,0,0,.1)',
		color: 'rgba(255,255,255,.85)',
		marginRight: '0.5em',

		'& .MuiSelect-outlined': {
			paddingTop: 0,
			paddingBottom: 0,
			backgroundColor: 'transparent',
		},

		'& .MuiSelect-iconOutlined': {
			color: 'rgba(255,255,255,.85)',
		},

		'& fieldset': {
			border: 0,
		}
	}
}));

export default useStyles;