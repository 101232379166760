import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import CustomSelect from './CustomSelect.jsx';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';

import { initViewer, importScene, createReceptors, createReceptorLabels, asignReceptorColors } from './VtkViewer.utils.js';
import useStyles from './VtkViewer.style.js';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

let viewer = null;
const currentActors = [];
const actorsTexture = [];
const actorsChimneys = [];
const actorsReceptors = [];
const actorsPlume = [];
const actorsFloor = [];
const labels = [];

function VtkViewer(props){
	const classes = useStyles();
	const [tab, setTab] = useState(0);
	const [loading, setLoading] = useState(true);

	const handleChangeDataType = (event) => {
		props.onChangeDataType(event.target.value);
	};

	const viewerElement = createRef();
	//const canvasElement = createRef();

	let holdingMouseTimeout = null;
	let holdingMouseInterval = null;

	const valuetext = (value) => {
		return value+1;
	};

	const selectValue = (event, newValue) => props.onChangeInstant(newValue);
	const nextValue = () => {
		if(!loading) props.onChangeInstant(props.value + 1);
	}
	const prevValue = () => {
		if(!loading) props.onChangeInstant(props.value - 1);
	}

	const rotate = (angle) => {
		// let actors = viewer.renderer.getActors();
		// if(actors.length > 0){
		// 	for(let actor of actorsChimneys) actor.rotateX(-90);
		// 	for(let actor of actors) actor.rotateZ(angle);
		// 	for(let actor of actorsChimneys) actor.rotateX(90);
		// }
		viewer.camera.roll(angle);
		viewer.renderWindow.render();
	};

	const rotateDown = (angle) => {
		rotate(angle);
		if(holdingMouseTimeout) clearTimeout(holdingMouseTimeout);
		if(holdingMouseInterval) clearInterval(holdingMouseInterval);
		holdingMouseTimeout = setTimeout(() => {
			rotate(angle);
			holdingMouseInterval = setInterval(rotate, 100, angle);
		}, 600);
	};

	const rotateUp = () => {
		if(holdingMouseTimeout){
			clearTimeout(holdingMouseTimeout);
			holdingMouseTimeout = null;
		}
		if(holdingMouseInterval){
			clearInterval(holdingMouseInterval);
			holdingMouseInterval = null;
		}
	};

	const resetCamera = () => {
		viewer.renderer.resetCamera();
		viewer.camera.zoom(5);
		viewer.renderWindow.render();
	};

	const clearViewer = () => {
		// if(currentActors && currentActors.length > 0){
		// 	viewer.renderer.removeAllActors();
		// 	setCurrentActors([]);
		// }
		// viewer.renderWindow.render();

		// let actors = viewer.renderer.getActors();
		// for(let actor of actors) viewer.renderer.removeActor(actor);
		// viewer.renderWindow.render();

		for(let actor of currentActors){
			if(!actorsTexture.includes(actor) && !actorsChimneys.includes(actor)){
				actor.setVisibility(false);
			}
		}
		viewer.renderWindow.render();
	};

	const fixLights = () => {
		let lights = viewer.renderer.getLights();
		for(let light of lights){
			light.setLightTypeToSceneLight();
			light.setDirectionAngle(0, 0);
		}
	};

	const importTexture = async () => {
		// Import Texture (from statics)
		let result = await importScene(viewer, '/static/scene/textura');
		for(let actor of result.actors){
			actorsTexture.push(actor);
			currentActors.push(actor);
		}
		//await appendTexture(viewer, '/static/scene/textura/1/texture.jpg', actorsTexture[0]);
	};

	const importChimneys = async () => {
		// Import Texture (from statics)
		let result = await importScene(viewer, '/static/scene/chimneys');
		for(let actor of result.actors){
			actorsChimneys.push(actor);
			currentActors.push(actor);
		}
	};

	const importPlume = async () => {
		// Import Plume
		let result = await importScene(viewer, props.urls[props.value].plume);

		for(let actor of result.actors){
			actorsPlume.push(actor);
			currentActors.push(actor);

			let mapper = actor.getMapper();
			let lookupTable = mapper.getLookupTable();
			lookupTable.setSaturationRange([0, 0]);
		}
	};

	const loadPlume = () => {
		for(let actor of actorsPlume) actor.setVisibility(true);
	};

	const importFloor = async () => {
		// Import Floor
		let type = 'floor_mp';
		if(props.dataType == 'mp') type = 'floor_mp';
		else if(props.dataType == 'no') type = 'floor_no';
		else if(props.dataType == 'so') type = 'floor_so';
		let result = await importScene(viewer, props.urls[props.value][type]);

		result.actors.forEach((actor, index) => {
			actorsFloor.push(actor);
			currentActors.push(actor);

			let mapper = actor.getMapper();
			let lookupTable = mapper.getLookupTable();
			lookupTable.setSaturationRange([1, 1]);
			if(index == 0){
				lookupTable.setHueRange([1, 1]);
			}
			else if(index == 1){
				lookupTable.setHueRange([0.1666, 0.1666]);
			}
			else if(index == 2){
				lookupTable.setHueRange([0.3333, 0.3333]);
			}
			else if(index == 3){
				lookupTable.setValueRange([0, 0]);
			}
		});


		//await importScene(viewer, '/static/scene/textura');
		// acts = viewer.renderer.getActors();
		// viewer.renderer.removeAllActors();
		// for(let act of acts) actorsFloor.push(act);
	};

	const loadFloor = () => {
		for(let actor of actorsFloor) actor.setVisibility(true);
	};

	const loadModels = async (tabValue) => {
		// clearViewer();

		// if(currentActors.length > 0) currentActors.splice(0, currentActors.length);
		// for(let actor of actorsTexture) currentActors.push(actor);

		// if(tabValue == 0){
		// 	for(let actor of actorsPlume) currentActors.push(actor);
		// 	for(let actor of actorsFloor) currentActors.push(actor);
		// }
		// else if(tabValue == 1){
		// 	for(let actor of actorsPlume) currentActors.push(actor);
		// }
		// else if(tabValue == 2){
		// 	for(let actor of actorsFloor) currentActors.push(actor);
		// }

		// Clear model and actors
		clearViewer();

		if(tabValue == 0){
			loadPlume();
			loadFloor();
		}
		else if(tabValue == 1){
			loadPlume();
		}
		else if(tabValue == 2){
			loadFloor();
		}
		viewer.renderWindow.render();
	};

	const handleTabsChange = async (event, newValue) => {
		if(!loading){
			setTab(newValue);
			await loadModels(newValue);
		}
	};

	useEffect(() => {
		// Show Loading Message
		setLoading(true);
		if(props.dataType == null) return;

		// Assign colors to receptors objects
		asignReceptorColors(props.receptors);

		// Load async data
		(async function(){
			if(viewerElement.current){
				if(!viewer) viewer = initViewer(viewerElement.current);

				// Load atocrs into renderer
				await importTexture();
				await importChimneys();
				//await importReceptors();
				await createReceptors(viewer);
				if(labels.length == 0){
					let res = await createReceptorLabels(viewer);
					for(let label of res.labels) labels.push(label);
				}

				await importPlume();
				await importFloor();

				await loadModels(tab);

				fixLights();

				// Set the camera position
				viewer.renderer.setBackground([0.1, 0.1, 0.1, 1]);
				viewer.renderer.resetCamera();
				viewer.camera.zoom(4);
				// window.__asd = viewer;
				// move focal point to camera position
				//let center = viewer.camera.getFocalPoint();
				//for(let actor of currentActors) actor.setOrigin(...center);

				// Render
				viewer.renderWindow.render();
				// Show all
				setLoading(false);
			}
		})();
		return function unmountVtkViewer(){
			// Clear actors from renderer
			let actors = viewer.renderer.getActors();
			for(let actor of actors) viewer.renderer.removeActor(actor);

			// Clear actors from local arrays
			currentActors.splice(0, currentActors.length);
			actorsTexture.splice(0, actorsTexture.length);
			actorsChimneys.splice(0, actorsChimneys.length);
			actorsPlume.splice(0, actorsPlume.length);
			actorsFloor.splice(0, actorsFloor.length);

			for(let label of labels){
				label.setEnabled(0);
				label.delete();
			}
			labels.splice(0, labels.length);

			// Unset viewer when element removed
			if(!viewerElement.current){
				viewer.camera.delete();
				viewer.renderWindow.delete();
				viewer.renderer.delete();
				viewer.genericRenderer.delete();
				viewer = null;
			}
		}
	}, [
		props.value,
		props.dataType,
	]);

	return (
		<div className={classes.root}>
			<Tabs value={tab} onChange={handleTabsChange} className={classes.tabsRoot}>
				<Tab label="Pluma+Isolineas" {...a11yProps(0)} />
				<Tab label="Pluma" {...a11yProps(1)} />
				<Tab label="Isolineas" {...a11yProps(2)} />
			</Tabs>
			<div className={classes.viewerElement} ref={viewerElement}>
				{/*<canvas className={classes.canvasElement} ref={canvasElement}></canvas>*/}
			</div>
			{(loading)&&( <div className={classes.loading}>Cargando Modelo...</div> )}
			<div className={classes.toolbar}>
				<Slider className={classes.slider}
					onChange={selectValue}
					value={props.value}
					getAriaValueText={valuetext}
					valueLabelFormat={valuetext}
					valueLabelDisplay="auto"
					step={1}
					marks
					min={0}
					max={props.urls.length - 1}
				/>
				<div className={classes.toolbarOptions}>
					<Box display="flex" alignItems="center">
						<IconButton size="small" className={classes.navButton} onClick={prevValue}>
							<NavigateBeforeIcon />
						</IconButton>
						<IconButton size="small" className={classes.navButton} onClick={nextValue}>
							<NavigateNextIcon />
						</IconButton>
						<Typography component="span">Instante: {props.value + 1}/{props.urls.length}</Typography>
					</Box>
					<Box display="flex" alignItems="center" alignSelf="flex-end">
						<CustomSelect disabled={loading} value={props.dataType} onChange={handleChangeDataType} />
						<IconButton size="small"
							className={classes.navButton}
							onClick={resetCamera}
						>
							<ControlCameraIcon />
						</IconButton>
						<IconButton size="small"
							className={classes.navButton}
							onMouseDown={() => rotateDown(-15)}
							onMouseUp={rotateUp}
						>
							<RotateRightIcon />
						</IconButton>
						<IconButton size="small"
							className={classes.navButton}
							onMouseDown={() => rotateDown(15)}
							onMouseUp={rotateUp}
						>
							<RotateLeftIcon />
						</IconButton>
					</Box>
				</div>
			</div>
		</div>
	);
};
VtkViewer.defaultProps = {
	urls: []
};

VtkViewer.propTypes = {
	urls: PropTypes.array.isRequired,
	value: PropTypes.number.isRequired,
	receptors: PropTypes.array.isRequired,
	dataType: PropTypes.string,
	onChangeInstant: PropTypes.func.isRequired,
	onChangeDataType: PropTypes.func.isRequired,
};

export default VtkViewer;