import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
	loading: {
		textAlign: 'center',
		color: theme.palette.primary.main,
		padding: theme.spacing(8) + 'px !important',
	}
}));

function CardLoading(props){
	const classes = useStyles();
	const text = props.text;

	return (
		<Card>
			<CardContent className={classes.loading}>
				<CircularProgress />
				<div>{ text }</div>
			</CardContent>
		</Card>
	);
}

CardLoading.propTypes = {
	text: PropTypes.string.isRequired,
};

export default CardLoading;