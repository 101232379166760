import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from "react-router-dom";
import { useSession } from '../../contexts/SessionContext.js';
import { useFetch } from '../../hooks/fetch.js';

import { fieldLimits } from '../../components/CreatePlanification/utils.js';

import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from '../../components/CreatePlanification/Header.jsx';
import Toolbar from '../../components/CreatePlanification/Toolbar.jsx';
import Grid from '../../components/CreatePlanification/Grid.jsx';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        position: 'relative',
        overflow: 'auto',
        maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    appBar: {
        position: 'sticky',
        top: 0,
    },
	mainStyles: {
        display: 'flex',
        flexDirection: 'column',
    },
    loading: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(250, 250, 250, 0.75)',
        padding: theme.spacing(16, 2, 2, 2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        userSelect: 'none',
    },
    loadingText: {
        marginTop: '0.75em',
    }
}));

const POST_URL = 'planifications/';

function validateFields(vectors){
	// iterate vectors
	for(let vector of vectors){
		// Iterate fields
		for(let data of vector){
            let field = fieldLimits[data.type];
            if(data.value == 0 || (data.value >= field[0] && data.value <= field[1])) continue;
            else return false;
		}
	}
	return true;
}

const validateValue = (fieldName, rowData) => {
    if(!(fieldName in fieldLimits)) throw new Error(`Field "${fieldName}" doesn't exists!`);
    // 1. Get field Ranges
    let field = fieldLimits[fieldName];
    // 2. Verify value in range
    if(rowData.value == 0 || (rowData.value >= field[0] && rowData.value <= field[1])) return;
    rowData.error = true;
};

const t1h = 3600000;
const t8h = 8 * t1h;

function getDatesDifference(date1, date2)
{
    return (date1.getTime() - date2.getTime()) / t1h;
}

function getRowFromField(rows, field){
    for(let row of rows){
        for(let col of row){
            if(field == col) return row;
        }
    }
    return null;
}

function createRow(){
    return [
        { error: false, value: 0, type: 'coal' },
        { error: false, value: 0, type: 'oil' },
        { error: false, value: 0, type: 'caoh_dos' },
        { error: false, value: 0, type: 'temperature' },
        { error: false, value: 0, type: 'rate' },
    ]
};

function createRowWithData(coal, oil, caoh_dos, temperature, rate){
    return [
        { error: false, value: coal, type: 'coal' },
        { error: false, value: oil, type: 'oil' },
        { error: false, value: caoh_dos, type: 'caoh_dos' },
        { error: false, value: temperature, type: 'temperature' },
        { error: false, value: rate, type: 'rate' },
    ]
};

function copyRow(sourceRow, targetRow){
    for(let colIndex in sourceRow){
        targetRow[colIndex].error = sourceRow[colIndex].error;
        targetRow[colIndex].value = sourceRow[colIndex].value;
    }
}

function generateData(quantity){
    let result = [];
    for(let i = 0; i < quantity; ++i) result.push(createRow());
    return result;
};

function CreatePlanification()
{
    let { planificationId } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
    const classes = useStyles();
    const { api } = useSession();

    // Init Data
    let date1 = new Date;
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);

    let date2 = new Date(date1.getTime() + t8h);

    //const TIME_ZONE = date1.toISOString().substr(16);

    // Init component's state
    const [data, setData] = React.useState([]);
    const [startDate, setStartDate] = React.useState(date1);
    const [endDate, setEndDate] = React.useState(date2);
    const [position, setPosition] = React.useState({ row: null, col: null, focus: false, data: null });
    const [toolbar, setToolbar] = React.useState('dates');
    const [based, setBased] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
	const [submited, setSubmited] = React.useState(false);
	//const [snackbarId, setSnackbarId] = React.useState(null);
    const [disableForm, setDisableForm] = React.useState(false);

    const maxEndDate = useFetch('/get-max-end-date', {
        parser(data){
            let newMaxEndDate = new Date(data);
            newMaxEndDate.setMinutes(0);
            newMaxEndDate.setSeconds(0);
            newMaxEndDate.setMilliseconds(0);
            return newMaxEndDate;
        }
    });

    const getFieldFromPosition = (row, col) => {
        for(let rowIndex = 0; rowIndex < data.length; ++rowIndex){
            let rowData = data[rowIndex];
            for(let colIndex = 0; colIndex < rowData.length; ++colIndex){
                if(rowIndex == row && colIndex == col){
                    return data[rowIndex][colIndex];
                }
            }
        }
        return null;
    }

    const focusInputField = (row, col) => {
        // Change position status
        let paramData = getFieldFromPosition(row, col);
        setPosition({
            row, col,
            focus: true,
            data: paramData,
        });
    }

    // Define component's methods
    const onAddRow = (quantity = 1) => {
        if(disableForm) return;
        for(let i = 0; i < quantity; ++i) data.push(createRow());
        let lastRowIndex = data.length - 1;
        setData(data.slice());

        // Add 1 hour to end date
        let newEndDate = new Date(endDate.getTime() + (quantity * t1h));
        setEndDate(newEndDate);

        // Focus Input in last row (on next tick)
        focusInputField(lastRowIndex, position.col);
    };

    const onInsertRow = (quantity = 1) => {
        if(disableForm) return;
        if(position.row != null && position.focus){
            // Row position to insert new rows
            let insertRowIndex = (position.row == data.length - 1)? position.row: position.row + 1;

            // Create new rows
            let newRows = [];
            for(let i = 0; i < quantity; ++i) newRows.push(createRow());
            // Insert and store new rows
            let newData = [
                ...data.slice(0, insertRowIndex),
                ...newRows,
                ...data.slice(insertRowIndex, data.length)
            ];
            setData(newData);

            // Add 1 hour to end date
            let newEndDate = new Date(endDate.getTime() + (quantity * t1h));
            setEndDate(newEndDate);

            // Focus Element under
            focusInputField(insertRowIndex, position.col);
        }
        else{
            onAddRow(quantity);
        }
    };

    const onCopy = (rowIndex = null) => {
        if(disableForm) return;
        // Check if any field is selected
        if(rowIndex == null && position.row == null) return;

        // Pick by position if no argument is passed
        if(rowIndex == null) rowIndex = position.row;

        let lastRowIndex = data.length - 1;
        if(rowIndex < lastRowIndex){
            // Clone Row
            let newData = [...data];
            copyRow(newData[rowIndex], newData[rowIndex + 1]);
            setData(newData);
            // Focus Element under
            focusInputField(rowIndex + 1, position.col);
        }
        else if(rowIndex == lastRowIndex){
            // Add a Row
            let newData = [...data, createRow()];
            // Clone Row
            copyRow(newData[rowIndex], newData[rowIndex + 1]);
            setData(newData);
            // Focus Element under
            focusInputField(rowIndex + 1, position.col);
            // Change End Date
            let newEndDate = new Date(endDate.getTime() + t1h);
            setEndDate(newEndDate);
        }
        else{
            focusInputField(rowIndex, position.col);
        }
    };

    const onErase = () => {
        if(disableForm) return;
        // Check if any field is selected
        if(!position || !position.row || !position.focus) return;

        // Erase data from row
        let row = data[position.row];
        if(!row) return;

        // Reset values
        row[0].value = 0;
        row[0].error = false;
        row[1].value = 0;
        row[1].error = false;
        row[2].value = 0;
        row[2].error = false;
        row[3].value = 0;
        row[3].error = false;
        row[4].value = 0;
        row[4].error = false;

        // Store
        setData(data.slice());

        // Re-focus element
        focusInputField(position.row, position.col);
    };

    const onRemove = () => {
        if(disableForm) return;
        // Check
        if(!position.focus) return;

        let diff = getDatesDifference(endDate, startDate);
        if(diff > 1){
            let firstRow = (position.row == 0);
            let lastRow = (position.row == (data.length - 1));
            // Remove current row
            data.splice(position.row, 1);
            // Push state
            setData(data.slice());
            // Remove one hour from end date unless its the first row
            if(firstRow){
                let newDate = new Date(startDate.getTime() + t1h);
                setStartDate(newDate);
                focusInputField(position.row, position.col);
            }
            else if(lastRow){
                let newDate = new Date(endDate.getTime() - t1h);
                setEndDate(newDate);
                focusInputField(position.row - 1, position.col);
            }
            else{
                let newDate = new Date(endDate.getTime() - t1h);
                setEndDate(newDate);
                focusInputField(position.row, position.col);
            }
        }
        else{
            focusInputField(position.row, position.col);
        }
    };

    const onRemoveByIndex = (rowIndex) => {
        if(disableForm) return;
        let diff = getDatesDifference(endDate, startDate);
        if(diff > 1){
            let lastRowIndex = data.length - 1;

            let isFirstRow = (rowIndex == 0);
            let isLastRow = (rowIndex == lastRowIndex);
            // Remove current row
            data.splice(rowIndex, 1);
            // Push state
            setData(data.slice());
            // Remove one hour from end date unless its the first row
            if(isFirstRow){
                // Add 1hout to startDate (reducing the span by 1hour)
                let newDate = new Date(startDate.getTime() + t1h);
                setStartDate(newDate);

                if(!position.focus) return;
                if(position.row > rowIndex){
                    focusInputField(position.row - 1, position.col);
                }
                else{
                    focusInputField(position.row, position.col);
                }
            }
            else if(isLastRow){
                // Substract 1hour to endDate
                let newDate = new Date(endDate.getTime() - t1h);
                setEndDate(newDate);

                if(!position.focus) return;
                if(position.row == lastRowIndex){
                    focusInputField(position.row - 1, position.col);
                }
                else{
                    focusInputField(position.row, position.col);
                }
            }
            else{
                // Substract 1hour to endDate
                let newDate = new Date(endDate.getTime() - t1h);
                setEndDate(newDate);

                if(!position.focus) return;
                if(position.row > rowIndex){
                    focusInputField(position.row - 1, position.col);
                }
                else{
                    focusInputField(position.row, position.col);
                }
            }
        }
        else if(position.focus){
            focusInputField(position.row, position.col);
        }
    };

    const onFocus = (rowData, rowIndex, colIndex) => {
        setPosition({ row: rowIndex, col: colIndex, focus: true, data: rowData });
    };

    const onChangeDate = (newDate, type, check = true) => {
        if(disableForm) return;
        if(check && type == 'end' && maxEndDate.data != null && newDate > maxEndDate.data) return;
        if(type == 'start' && newDate < endDate){
            let changedHours = getDatesDifference(startDate, newDate);

            // Add Hours (when not empty)
            if(data.length > 0 && changedHours > 0){
                // add hours
                for(let i = 0; i < changedHours; ++i) data.unshift(createRow());
                setData(data.slice());
            }
            // Remove Hours (when not empty)
            else if(data.length > 0 && changedHours < 0){
                changedHours = (-1) * changedHours;
                setData(data.slice(changedHours, data.length));
            }
            // Add Hours (when empty)
            else if(data.length == 0 && changedHours > 0){
                let amount = getDatesDifference(endDate, startDate);
                for(let i = 0; i < amount; ++i) data.push(createRow());
                setData(data.slice());
            }

            setStartDate(newDate);
        }
        else if(type == 'end' && newDate > startDate){
            let changedHours = getDatesDifference(newDate, endDate);

            // Add Hours (when not empty)
            if(data.length > 0 && changedHours > 0){
                // add hours
                for(let i = 0; i < changedHours; ++i) data.push(createRow());
                setData(data.slice());
            }
            // Remove Hours (when not empty)
            else if(data.length > 0 && changedHours < 0){
                setData(data.slice(0, data.length + changedHours));
            }
            // Add Hours (when empty)
            else if(data.length == 0 && changedHours > 0){
                let amount = getDatesDifference(endDate, startDate);
                for(let i = 0; i < amount; ++i) data.push(createRow());
                setData(data.slice());
            }

            setEndDate(newDate);
        }
    };

    const onChange = (event, rowData) => {
        // WHEN: an input changes its value.
        let value = event.target.value;

        // Remove alert state
        rowData.error = false;

        if(value == '') rowData.value = '';
        else{
            value = parseFloat(value);
            if(!isNaN(value)){
                rowData.value = value;
            }
            else rowData.value = 0.0;
        }
        setData(data.slice());
    };

    const onLeave = (event, rowData) => {
        // Change position's focus state
        setPosition({
            ...position,
            focus: false,
        });

        // Get Value
        let value = event.target.value;
        if(value == ''){
            rowData.value = 0.0;
            setData(data.slice());
        }
        else{
            value = parseFloat(value);
            if(!isNaN(value)){
                if(rowData.value != value){
                    rowData.value = value;
                    setData(data.slice());
                }
            }
            else{
                rowData.value = 0.0;
                setData(data.slice());
            }
        }

        validateValue(rowData.type, rowData);
    };

    const onSave = async (simulate = false) => {
        if(disableForm){
            enqueueSnackbar('Faltan datos necesarios para crear una planificacion!', { variant: 'info' });
            return;
        }

        setSubmited(true);

        // Check alerts
        if(!validateFields(data)){
            enqueueSnackbar('Hay campos inválidos!', { variant: 'error' });
            //setSnackbarId(id);
            setSubmited(false);
            return;
        }

        // Generate Data for backend
        let vectors = data.map((vector, index) => {
            let span = new Date(startDate.getTime() + (t1h * index));
            let vectorData = { span };
            for(let data of vector) vectorData[data.type] = data.value;
            return vectorData;
        });

		let postData = {
			original: (based == 0)? null: based,
			start_date: startDate,
			end_date: endDate,
			vectors,
			simulate,
		};

		// Make POST request
		try{
			let data = await api.post(POST_URL, postData);
			if (data) {
				history.push('/planification/' + data.id);
			}
			else{
				throw new Error('El contenido de la respuesta es invalida!');
			}
		}
		catch(err){
            setSubmited(false);
			enqueueSnackbar('Ocurrio un error al intentar guardar los datos!', { variant: 'error' });
        }
    };

    React.useEffect(() => {
        if(maxEndDate.status == 'done'){
            (async () =>{
                let newStartDate;
                let newEndDate;
                // Set Rows (Vectors)
                if(!!planificationId){
                    let planId = parseInt(planificationId);
                    setBased(planId);
                    let data = await api.get(`planifications/${planId}/`);
                    if(data){
                        newStartDate = new Date(data.start_date);
                        newEndDate = new Date(data.end_date);

                        setStartDate(newStartDate);
                        setEndDate(newEndDate);

                        let vectorsList = [];
                        for(let vector of data.vectors){
                            vectorsList.push(createRowWithData(
                                vector.coal,
                                vector.oil,
                                vector.caoh_dos,
                                vector.temperature,
                                vector.rate,
                            ));
                        }
                        setData(vectorsList);
                    }
                    else{
                        newStartDate = startDate;
                        newEndDate = endDate;
                        let numberRows = getDatesDifference(endDate, startDate) + 1;
                        let rows = generateData(numberRows);
                        setData(rows);
                    }
                }
                else{
                    newStartDate = startDate;
                    newEndDate = endDate;
                    let numberRows = getDatesDifference(endDate, startDate) + 1;
                    let rows = generateData(numberRows);
                    setData(rows);
                }

                // Re-define "start" & "end" dates
                if(maxEndDate.data > newStartDate && maxEndDate.data < newEndDate){
                    setEndDate(maxEndDate.data);
                }
                else if(maxEndDate.data < newStartDate){
                    setDisableForm(true);
                    enqueueSnackbar('Faltan datos necesarios para crear una planificacion!', { variant: 'info' });
                }
    
                setLoading(false);
            })().catch(err => {
                console.error(err);
                enqueueSnackbar('Ocurrió un error al intentar obtener los datos!', { variant: 'error' });
            });
        }
    }, [maxEndDate.status]); 

    React.useEffect(() => {
        if(position.focus == true){
            // Focus HTML Element
            let elem = document.getElementById(`input-${position.row}-${position.col}`);
            if(elem) elem.focus();
        }
    }, [position]);

    // Component's Template
    return (
        <div className={classes.rootContainer}>
            <AppBar className={classes.appBar} color="inherit" position="fixed" elevation={0}>
                <Header
                    loading={loading||submited}
                    onSelectMenu={setToolbar}
                    toolbar={toolbar}
                    basedPlanification={based}
                />
                <Toolbar
                    loading={loading||submited}
                    onErase={onErase}
                    onRemove={onRemove}
                    onCopy={onCopy}
                    onInsertRow={onInsertRow}
                    onAddRow={onAddRow}
                    onChangeDate={onChangeDate}
                    onSave={onSave}
                    startDate={startDate}
                    endDate={endDate}
                    maxEndDate={maxEndDate.data}
                    isUnfocus={position.row == null || position.col == null}
                    toolbar={toolbar}
                />
            </AppBar>
            <main className={classes.mainStyles}>
                {(loading)&&(
                    <div className={classes.loading}>
                        <CircularProgress />
                        <span className={classes.loadingText}>Cargando...</span>
                    </div>
                )}
                <Grid
                    startDate={startDate}
                    data={data}
                    onFocus={onFocus}
                    onChange={onChange}
                    onLeave={onLeave}
                    onAddRow={onAddRow}
                    onRemove={onRemoveByIndex}
                    onCopy={onCopy}
                    disabled={disableForm}
                />
            </main>
        </div>
    )
}

CreatePlanification.propTypes = {};

export default CreatePlanification;
