import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useSession } from '../../contexts/SessionContext.js';

import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import GetAppIcon from '@material-ui/icons/GetApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
	header: {
		'& >.MuiCardHeader-action': {
			marginTop: '0px !important',
			marginRight: '0px !important',
		}
	},
	subtitle: {
		color: grey[700],
	},
	titleId: {
		color: theme.palette.primary.main,
	},
	backButton: {
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(-0.5),
	}
}));

function PlanificacionHeader({ id, original, start_at, end_at, videos, videosDisabled })
{
	// Snackbar API
	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();
	const classes = useStyles();
	const { api } = useSession();

	// async function getVideoUrl(){
	// 	enqueueSnackbar('Iniciando la descarga del video...', { variant: 'info' });
	// 	let responseUrl = await api.get(`planification/${id}/video`);
	// 	if(!!responseUrl){
	// 		window.open(responseUrl, '_blank');
	// 	}
	// 	else{
	// 		enqueueSnackbar('No se pudo crear el enlace al video!', { variant: 'warning' });
	// 	}
	// }

	// const [anchorEl, setAnchorEl] = React.useState(null);
	// const handleDownloadOpen = (event) => {
	// 	setAnchorEl(event.currentTarget);
	// };
	// const handleDownloadClose = (type) => {
	// 	setAnchorEl(null);
	// 	if(type){
	// 		let url = videos[type];
	// 		if(url) window.open(videos[type], '_blank').focus();
	// 	}
	// };

	return (
		<CardHeader
			// action={
			// 	<React.Fragment>
			// 		<Button
			// 			aria-controls="simple-menu"
			// 			aria-haspopup="true"
			// 			onClick={handleDownloadOpen}
			// 			disabled={videosDisabled}
			// 			disableElevation
			// 			color="primary"
			// 			variant="contained"
			// 			startIcon={<GetAppIcon />} 
			// 		> 
			// 			Descargar Video
			// 		</Button>
			// 		<Menu
			// 			id="simple-menu"
			// 			anchorEl={anchorEl}
			// 			keepMounted
			// 			open={Boolean(anchorEl)}
			// 			onClose={handleDownloadClose}
			// 		>
			// 			<MenuItem
			// 				onClick={() => handleDownloadClose('mp')}
			// 				disabled={videos.mp == null}
			// 			>
			// 				Material Particulado
			// 			</MenuItem>
			// 			<MenuItem
			// 				onClick={() => handleDownloadClose('no')}
			// 				disabled={videos.no == null}
			// 			>
			// 				NO<sub>X</sub>
			// 			</MenuItem>
			// 			<MenuItem
			// 				onClick={() => handleDownloadClose('so')}
			// 				disabled={videos.so == null}
			// 			>
			// 				SO<sub>2</sub>
			// 			</MenuItem>
			// 		</Menu>
			// 	</React.Fragment>
			// }
			title={
				<>
					<IconButton size="small" className={classes.backButton} onClick={() => history.push('/planifications')}>
						<ChevronLeftIcon />
					</IconButton>
					Planificaci&oacute;n <span className={classes.titleId}>#{id}</span>
				</>
			}
			subheader={
				<>
					{(original != null)&&(
						<div>
							<Typography variant="caption" className={classes.subtitle}>
								Creado a partir de: <Link to={'/planificacion/' + original + '/'}>#{original}</Link>
							</Typography>
						</div>
					)}
					<div>
						<Typography variant="caption" className={classes.subtitle}>
							Inicio: { start_at } / T&eacute;rmino: { end_at }
						</Typography>
					</div>
				</>
			}
			className={classes.header}
		/>
	);
};

PlanificacionHeader.propTypes = {
	id: PropTypes.string.isRequired,
	start_at: PropTypes.string.isRequired,
	end_at: PropTypes.string.isRequired,
	videos: PropTypes.object.isRequired,
	videosDisabled: PropTypes.bool.isRequired,
	original: PropTypes.number,
};

export default PlanificacionHeader;