import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const inputFormat = "dd MMMM yyyy, HH:mm";
const timeFormat = "Hora";
const today = new Date;

today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octube',
    'Noviembre',
    'Diciembre'
];

const zeroPadd = (value) => ('00'+value).substr(-2);

class CustomInput extends React.Component {
    render(){
        return (
            <Button
                size="small"
                disabled={this.props.buttonDisabled}
                color="primary"
                variant="contained"
                disableElevation
                onClick={this.props.onClick}
            >
                {this.props.date.getDate()} { months[this.props.date.getMonth()] } { this.props.date.getFullYear() } - { this.props.date.getHours() }:{ zeroPadd(this.props.date.getMinutes()) }
            </Button>
        )
    }
};

CustomInput.propTypes = {
    date: PropTypes.any,
    buttonDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.string,
};

const CustomContainer = ({ children }) => {
    return (
        <Box className="react-datepicker" boxShadow={4}>
            {children}
        </Box>
    );
};

CustomContainer.propTypes = {
    children: PropTypes.any,
};

const renderDayContents = (day) => {
    return (
        <div className="box-day-wrapper">
            <div className="box-day">{day}</div>
        </div>
    );
};

const CustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
    <div className="react-datepicker__current-month">
        <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--previous react-datepicker__navigation--previous--with-time"
            aria-label="Mes Anterior"
            disabled={prevMonthButtonDisabled}
            onClick={decreaseMonth}
        >
            Mes Anterior
        </button>
        <div className="text">{ months[date.getMonth()] } { date.getFullYear() }</div>
        <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--next react-datepicker__navigation--next--with-time"
            aria-label="Mes Siguiente"
            disabled={nextMonthButtonDisabled}
            onClick={increaseMonth}
        >
            Mes Siguiente
        </button>
    </div>
);
CustomHeader.propTypes = {
    date: PropTypes.any,
    decreaseMonth: PropTypes.any,
    increaseMonth: PropTypes.any,
    prevMonthButtonDisabled: PropTypes.any,
    nextMonthButtonDisabled: PropTypes.any
};

function ToolbarDatePicker({ disabled, startDate, endDate, onChange, maxEndDate })
{
    return (
        <>
            <div className="label">Rango:</div>
            <div className="date-from">
                <DatePicker
                    renderCustomHeader={CustomHeader}
                    showTimeSelect
                    timeIntervals={60}
                    timeCaption={timeFormat}
                    timeFormat="HH:mm"
                    dateFormat={inputFormat}
                    closeOnScroll={true}
                    onChange={(date) => onChange(date, 'start')}
                    selected={startDate}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={today}
                    customInput={<CustomInput buttonDisabled={disabled} date={startDate} />}
                    calendarContainer={CustomContainer}
                    renderDayContents={renderDayContents}
                    disabled={disabled}
                    maxDate={maxEndDate}
                />
            </div>
            <div className="label">►</div>
            <div className="date-to">
                <DatePicker
                    renderCustomHeader={CustomHeader}
                    showTimeSelect
                    timeIntervals={60}
                    timeCaption={timeFormat}
                    timeFormat="HH:mm"
                    dateFormat={inputFormat}
                    closeOnScroll={true}
                    onChange={(date) => onChange(date, 'end')}
                    selected={endDate}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    customInput={<CustomInput buttonDisabled={disabled} date={endDate} />}
                    calendarContainer={CustomContainer}
                    renderDayContents={renderDayContents}
                    disabled={disabled}
                    maxDate={maxEndDate}
                />
            </div>
        </>
    )
};

ToolbarDatePicker.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    maxEndDate: PropTypes.instanceOf(Date),
};

export default ToolbarDatePicker;