import vtkHttpSceneLoader from 'vtk.js/Sources/IO/Core/HttpSceneLoader';
import vtkGenericRenderWindow from 'vtk.js/Sources/Rendering/Misc/GenericRenderWindow';
import vtkInteractorStyleManipulator from 'vtk.js/Sources/Interaction/Style/InteractorStyleManipulator';
//import vtkHttpDataSetReader from 'vtk.js/Sources/IO/Core/HttpDataSetReader';
//import vtkPixelSpaceCallbackMapper from 'vtk.js/Sources/Rendering/Core/PixelSpaceCallbackMapper';
import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
//import vtkTexture from 'vtk.js/Sources/Rendering/Core/Texture';
import vtkLabelWidget from 'vtk.js/Sources/Interaction/Widgets/LabelWidget';
//import vtkConeSource from 'vtk.js/Sources/Filters/Sources/ConeSource';
import vtkCylinderSource from 'vtk.js/Sources/Filters/Sources/CylinderSource';
//import vtkSphereMapper from 'vtk.js/Sources/Rendering/Core/SphereMapper';
import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';

import vtkMouseCameraTrackballMultiRotateManipulator from 'vtk.js/Sources/Interaction/Manipulators/MouseCameraTrackballMultiRotateManipulator';
import vtkMouseCameraTrackballPanManipulator from 'vtk.js/Sources/Interaction/Manipulators/MouseCameraTrackballPanManipulator';
import vtkMouseCameraTrackballRollManipulator from 'vtk.js/Sources/Interaction/Manipulators/MouseCameraTrackballRollManipulator';
import vtkMouseCameraTrackballRotateManipulator from 'vtk.js/Sources/Interaction/Manipulators/MouseCameraTrackballRotateManipulator';
import vtkMouseCameraTrackballZoomManipulator from 'vtk.js/Sources/Interaction/Manipulators/MouseCameraTrackballZoomManipulator';
import vtkMouseCameraTrackballZoomToMouseManipulator from 'vtk.js/Sources/Interaction/Manipulators/MouseCameraTrackballZoomToMouseManipulator';
import vtkGestureCameraManipulator from 'vtk.js/Sources/Interaction/Manipulators/GestureCameraManipulator';

//import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';

const leftButton = 1;
const middleButton = 2;
const rightButton = 3;

const manipulatorFactory = {
	None: null,
	Pan: vtkMouseCameraTrackballPanManipulator,
	Zoom: vtkMouseCameraTrackballZoomManipulator,
	Roll: vtkMouseCameraTrackballRollManipulator,
	Rotate: vtkMouseCameraTrackballRotateManipulator,
	MultiRotate: vtkMouseCameraTrackballMultiRotateManipulator,
	ZoomToMouse: vtkMouseCameraTrackballZoomToMouseManipulator,
};

function customInteractor(interactorStyle){
	interactorStyle.removeAllMouseManipulators();

	// LeftButton behaviour
	const leftManipulator = manipulatorFactory.Rotate.newInstance();
	leftManipulator.setButton(leftButton);
	interactorStyle.addMouseManipulator(leftManipulator);

	// RightButton behaviour
	const rightManipulator = manipulatorFactory.Pan.newInstance();
	// const oldOnMouseMove = rightManipulator.onMouseMove;
	// rightManipulator.onMouseMove = (interactor, renderer, position) => {
	// 	interactorStyle.setCenterOfRotation([7150, 4600, 281.19866943359375]);
	// 	oldOnMouseMove(interactor, renderer, position);
	// };
	//console.log(rightManipulator);
	rightManipulator.setButton(rightButton);
	interactorStyle.addMouseManipulator(rightManipulator);

	// MiddleButton/Scroll behaviour
	const middleManipulator = manipulatorFactory.ZoomToMouse.newInstance();
	middleManipulator.setButton(middleButton);
	middleManipulator.setScrollEnabled(true);
	interactorStyle.addMouseManipulator(middleManipulator);

	// Always add gesture
	interactorStyle.addGestureManipulator(
		vtkGestureCameraManipulator.newInstance()
	);
}

export function initViewer(container){
	const viewer = {};

	// Objects
	const genericRenderer = vtkGenericRenderWindow.newInstance();
	genericRenderer.setContainer(container);
	genericRenderer.setBackground([0.1, 0.1, 0.1]);
	genericRenderer.resize();

	const interactorStyle = vtkInteractorStyleManipulator.newInstance();
	interactorStyle.setCenterOfRotation([7150, 4600, 281.19866943359375]);
	genericRenderer.getInteractor().setInteractorStyle(interactorStyle);
	customInteractor(interactorStyle);

	viewer.renderer = genericRenderer.getRenderer();
	viewer.renderWindow = genericRenderer.getRenderWindow();
	viewer.genericRenderer = genericRenderer;
	viewer.camera = viewer.renderer.getActiveCamera();
	viewer.camera.setFocalPoint(7540, 2817, 52);

	// Some config
	viewer.renderer.setLightFollowCamera(false);
	viewer.renderer.removeAllLights();

	return viewer;
};

const receptors = {
	huasco_ii: {
		coord: [9197.23, 4419.21, 47.155],
		label: 'Huasco II',
		color: [0, 1, 0],
	},
	skate_park: {
		coord: [11386.4, 4573.2, 47.3632],
		label: 'Skate Park',
		color: [0, 1, 0],
	},
	poblacion_huasco: {
		coord: [9844.67, 4415.26, 61.8301],
		label: 'Población Huasco 2',
		color: [0, 1, 0],
	},
	escuela_jose_miguel_carrera: {
		coord: [10333.3, 4166.99, 70.7492],
		label: 'Escuela José Miguel Carrera',
		color: [0, 1, 0],
	},
	hospital: {
		coord: [10495.5, 4741.86, 45.6958],
		label: 'Hospital',
		color: [0, 1, 0],
	},
	municipalidad: {
		coord: [10047.1, 4702.02, 40.1882],
		label: 'Municipalidad',
		color: [0, 1, 0],
	},
	escuela_english_college: {
		coord: [9439.51, 4829.86, 48.733],
		label: 'Escuela English College',
		color: [0, 1, 0],
	},
};

export async function asignReceptorColors(receptorColors){
	for(let receptor of receptorColors){
		if(receptor.name in receptors){
			if(receptor.color == 'green'){
				receptors[receptor.name].color = [0, 1, 0];
			}
			else if(receptor.color == 'yellow'){
				receptors[receptor.name].color = [1, 1, 0];
			}
			else if(receptor.color == 'red'){
				receptors[receptor.name].color = [1, 0, 0];
			}
		}
	}
};

export async function importScene(viewerObject, url){
	return new Promise((resolve, reject) => {
		const sceneImporter = vtkHttpSceneLoader.newInstance({ renderer: viewerObject.renderer });
		sceneImporter.setUrl(url);
		sceneImporter.onReady(() => {
			let scenes = sceneImporter.getScene();
			return resolve({
				//scenes: sceneImporter.getScene(),
				actors: scenes.map(scene => scene.actor),
			});
		});
	});
};

export async function createReceptors(viewerObject){
	return new Promise((resolve, reject) => {
		const actors = [];
		for(let receptor_name in receptors){
			// Create Cylinder
			const cylinderSource = vtkCylinderSource.newInstance({
				direction: [0, 0, 1],
				center: receptors[receptor_name].coord,
				resolution: 15,
				height: 20,
				radius: 50,
			});
			const actor = vtkActor.newInstance();
			const mapper = vtkMapper.newInstance();

			actor.setMapper(mapper);
			actor.getProperty().setRepresentation(2);
			actor.getProperty().setColor(...receptors[receptor_name].color);
			actors.push(actor);

			mapper.setInputConnection(cylinderSource.getOutputPort());

			viewerObject.renderer.addActor(actor);
		}

		viewerObject.renderWindow.render();

		return resolve({ actors });
	});
};

export async function createReceptorLabels(viewerObject){
	return new Promise((resolve, reject) => {
		const labels = [];
		for(let receptor_name in receptors){
			// Create label
			const widget = vtkLabelWidget.newInstance();
			widget.setInteractor(viewerObject.renderWindow.getInteractor());
			widget.setEnabled(1);
			widget.getWidgetRep().setLabelText(receptors[receptor_name].label);
			widget.getWidgetRep().setWorldPosition(receptors[receptor_name].coord);
			labels.push(widget);
		}

		viewerObject.renderWindow.render();

		return resolve({ labels });
	});
};

// export async function resetReceptorLabels(viewerObject, labels){
// 	return new Promise((resolve, reject) => {
// 		for(let widget of labels){
// 			// Reset label
// 			console.log(widget);
// 			widget.setInteractor(viewerObject.renderWindow.getInteractor());
// 			widget.delete();
// 		}

// 		viewerObject.renderWindow.render();

// 		return resolve();
// 	});
// };

// export async function testDataSet(viewerObject){
// 	return new Promise(async function(resolve, reject){
// 		try{
// 			const coneSource = vtkConeSource.newInstance({ height: 600, radius: 400 });
// 			const mapper = vtkSphereMapper.newInstance({ radius: 100 });
// 			mapper.setInputConnection(coneSource.getOutputPort());

// 			const actor = vtkActor.newInstance();
// 			actor.setMapper(mapper);

// 			const psMapper = vtkPixelSpaceCallbackMapper.newInstance();
// 			psMapper.setInputConnection(coneSource.getOutputPort());
// 			psMapper.setCallback(console.log);

// 			const textActor = vtkActor.newInstance();
// 			textActor.setMapper(psMapper);

// 			viewerObject.renderer.addActor(actor);
// 			viewerObject.renderer.addActor(textActor);

// 			return resolve();
// 		}
// 		catch(err){
// 			return reject(err);
// 		}
// 	});
// };

// export async function importDataSet(viewerObject, url, callback){
// 	return new Promise(async function(resolve, reject){
// 		try{
// 			const reader = vtkHttpDataSetReader.newInstance();

// 			// Source data
// 			let res = await reader.setUrl(url);
// 			await reader.loadData();

// 			// Spheres Actors
// 			const mapper = vtkSphereMapper.newInstance({ radius: 20 });
// 			mapper.setInputConnection(reader.getOutputPort());

// 			const actor = vtkActor.newInstance();
// 			actor.setMapper(mapper);

// 			// const psMapper = vtkPixelSpaceCallbackMapper.newInstance();
// 			// psMapper.setInputConnection(reader.getOutputPort());
// 			// psMapper.setCallback(callback);

// 			// const textActor = vtkActor.newInstance();
// 			// textActor.setMapper(psMapper);

// 			viewerObject.renderer.addActor(actor);
// 			//viewerObject.renderer.addActor(textActor);

// 			return resolve({
// 				actors: [ actor ],
// 			});
// 		}
// 		catch(err){
// 			return reject(err);
// 		}
// 	});
// };

// export async function createReceptor(viewerObject, actor, index){
// 	return new Promise(async function(resolve, reject){
// 		try{
// 			// Label
// 			const widget = vtkLabelWidget.newInstance();
// 			widget.setInteractor(viewerObject.renderWindow.getInteractor());
// 			widget.setEnabled(1);
// 			widget.getWidgetRep().setLabelText(`Receptor Index ${index}`);
// 			widget.getWidgetRep().setWorldPosition(actor.getPosition());

// 			// Spheres Mapper
// 			const mapper = vtkSphereMapper.newInstance({ radius: 20 });

// 			// Change Mapper
// 			actor.setMapper(mapper);

// 			let lookupTable = mapper.getLookupTable();
// 			lookupTable.setSaturationRange([1, 1]);
// 			lookupTable.setHueRange([0.3333, 0.3333]);

// 			return resolve();
// 		}
// 		catch(err){
// 			return reject(err);
// 		}
// 	});
// };

// export async function appendTexture(viewerObject, url, actor){
// 	return new Promise(async function(resolve, reject){
// 		try{
// 			const img = new Image();
// 			img.addEventListener('load', () => {
// 				const texture = vtkTexture.newInstance();
// 				texture.setInterpolate(true);
// 				texture.setImage(img);
// 				actor.addTexture(texture);
// 				viewerObject.renderWindow.render();
// 				resolve();
// 			}, {
// 				once: true,
// 			});
// 			img.src = url;
// 		}
// 		catch(err){
// 			return reject(err);
// 		}
// 	});
// };

// export async function appendLabel(viewerObject, actor, text){
// 	return new Promise(async function(resolve, reject){
// 		try{
// 			// console.log(actor);
// 			// console.log(actor.getCenter());
// 			// console.log(actor.getOrigin());
// 			// console.log(actor.getPosition());
// 			const widget = vtkLabelWidget.newInstance();
// 			widget.setInteractor(viewerObject.renderWindow.getInteractor());
// 			widget.setEnabled(1);
// 			widget.getWidgetRep().setLabelText(text);
// 			widget.getWidgetRep().setWorldPosition(actor.getPosition());
// 			return resolve();
// 		}
// 		catch(err){
// 			return reject(err);
// 		}
// 	});
// };

// export const verde = vtkColorTransferFunction.newInstance();
// verde.addRGBPoint(10.0, 0.0, 1.0, 0.0);
// verde.addRGBPoint(75.0, 0.0, 1.0, 0.0);

// export const amarillo = vtkColorTransferFunction.newInstance();
// amarillo.addRGBPoint(75.0, 1.0, 1.0, 0.0);
// amarillo.addRGBPoint(120.0, 1.0, 1.0, 0.0);

// export const rojo = vtkColorTransferFunction.newInstance();
// rojo.addRGBPoint(120.0, 1.0, 0.0, 0.0);
// rojo.addRGBPoint(1e6, 1.0, 0.0, 0.0);
