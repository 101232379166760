import React, { useState, useMemo, createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { usePersistentState } from '../hooks/persistent-state';

const API_VERSION = 1;
const BASE_URL = window.location.origin + '/api/v' + API_VERSION + '/';

const DEFAULT_AXIOS = axios.create({
	baseURL: BASE_URL,
	headers: {
		common: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		}
	},
});

class SessionRequest {
	constructor(){
		this.enqueueSnackbar = null;
		this.request = null;
	}

	parseResponse(response){
		if('data' in response && typeof(response.data) == 'object' && 'message' in response.data && 'content' in response.data){
			let data = response.data;
			if(this.enqueueSnackbar != null){
				if(data.message instanceof Array){
					for(let msg of data.message){
						this.enqueueSnackbar(msg.text, { variant: msg.type });
					}
				}
				else if(typeof(data.message) == 'object' && data.message != null){
					this.enqueueSnackbar(data.message.text, { variant: data.message.type });
				}
			}
			return data.content;
		}
		return response;
	}

	options(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.options(...args).then(this.parseResponse.bind(this));
	}

	get(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.get(...args).then(this.parseResponse.bind(this));
	}

	post(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.post(...args).then(this.parseResponse.bind(this));
	}

	put(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.put(...args).then(this.parseResponse.bind(this));
	}

	patch(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.patch(...args).then(this.parseResponse.bind(this));
	}

	delete(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.delete(...args).then(this.parseResponse.bind(this));
	}
}

const createAxiosInstance = (token) => DEFAULT_AXIOS.create({
	headers: {
		common: { 'Authorization': `Token ${token}` }
	},
});

const sessionRequest = new SessionRequest;

const SessionContext = createContext({});

export const useSession = () => useContext(SessionContext);

const emptyRegistryData = () => ({
	isAuthenticated: false,
	admin: false,
	expiration: null,
	token: null,
	user_id: null,
	permissions: [],
	places: [],
	currentPlace: null,
});

export function SessionProvider({ children }){
	const { enqueueSnackbar } = useSnackbar();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [registry, setRegistry, clearCacheRegistry] = usePersistentState('session', emptyRegistryData());

	useEffect(() => {
		// enqueueSnackbar
		sessionRequest.enqueueSnackbar = enqueueSnackbar;
		sessionRequest.request = DEFAULT_AXIOS;
		// expiration
		if(registry.expiration != null){
			const now = new Date;
			let expiration = new Date(registry.expiration);
			if(now > expiration){
				setRegistry(emptyRegistryData());
			}
		}
		// admin
		if(registry.admin) setIsAdmin(registry.admin);
		// authenticated
		if(registry.isAuthenticated){
			sessionRequest.request = createAxiosInstance(registry.token);
			setIsAuthenticated(registry.isAuthenticated);
		}
	}, []);

	const handleLogin = (data) => {
		const { admin, token, permissions, places, user_id } = data;

		sessionRequest.request = createAxiosInstance(token);

		const expiration = new Date;
		expiration.setDate(expiration.getDate() + 1);

		const currentPlace = (places.length > 0)? places[0].name: null;

		setRegistry({
			isAuthenticated: true,
			admin: admin,
			expiration: expiration,
			token: token,
			user_id: user_id,
			permissions: permissions,
			places: places,
			currentPlace: currentPlace,
		});

		setIsAuthenticated(true);
		setIsAdmin(admin);
	};

	const handleLogout = () => {
		sessionRequest.request = DEFAULT_AXIOS;
		setRegistry(emptyRegistryData());
		clearCacheRegistry();
		setIsAuthenticated(false);
		setIsAdmin(false);
	};

	const hasPermission = (perm) => {
		const perms = registry.permissions;
		if(perms instanceof Array && perms.length > 0){
			return perms.includes(perm);
		}
		return false;
	};

	const changePlace = (placeName) => {
		const place = registry.places.find(p => p.name == placeName);
		if(!place) throw new Error(`The place "${placeName}" doesn't exists!`);
		setRegistry(reg => ({ ...reg, currentPlace: placeName }));
	};

	const sessionValue = {
		api: sessionRequest,
		isAuthenticated,
		isAdmin,
		session: registry,
		login: handleLogin,
		logout: handleLogout,
		hasPermission,
		changePlace,
	}

	return (
		<SessionContext.Provider value={sessionValue}>
			{children}
		</SessionContext.Provider>
	);
};
SessionProvider.propTypes = {
	children: PropTypes.any.isRequired,
};