import { makeStyles } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';

export default makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	viewer: {
		marginBottom: theme.spacing(2),
	},
	loadingHeader: {
		marginBottom: theme.spacing(2),
	},
	receptorName: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	receptorSuccess: {
		color: '#fff',
		padding: theme.spacing(0.2),
		backgroundColor: green[500],
		borderRadius: theme.spacing(0.2),
		'& >.MuiSvgIcon-root': {
			marginTop: '5px'
		},
	},
	receptorWarning: {
		color: '#fff',
		padding: theme.spacing(0.2),
		backgroundColor: yellow[800],
		borderRadius: theme.spacing(0.2),
		'& >.MuiSvgIcon-root': {
			marginTop: '5px'
		},
	},
	receptorAlert: {
		color: '#fff',
		padding: theme.spacing(0.2),
		backgroundColor: red[500],
		borderRadius: theme.spacing(0.2),
	},
	buttonNav: {
		width: '100%',
		padding: theme.spacing(2),
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,.1)',
		},
		borderColor: grey[300],
	},
	buttonNoNav: {
		width: '100%',
		padding: theme.spacing(2),
		borderColor: grey[300],
	},
	cellVertical: {
		zIndex: '0',
	},
	cellAlignVertical: {
		position: 'sticky',
		left: 0,
		verticalAlign: 'bottom',
		backgroundColor: '#fff',
		zIndex: '1',
	},
	tableHeaderCell: {
		padding: theme.spacing(1),
		backgroundColor: '#fff',
		maxWidth: 200,
		minWidth: 65,
	},
	tableBodyCell: {
		padding: theme.spacing(1),
	},
	colSticky: {
		position: 'sticky',
		left: 0,
		backgroundColor: '#fff',
	},
	cellSelected: {
		backgroundColor: grey[200],
	},
	dateCell: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	lastCell: {
		padding: theme.spacing(0.5)+'px !important',
		width: 'auto',
	},
}));