import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import grey from '@material-ui/core/colors/grey';
import { appendClasses } from '../../utils/index.js';

const useStyles = makeStyles(theme => ({
	hourCell: {
		padding: theme.spacing(0.75, 2),
		borderRightWidth: 1,
		borderRightColor: grey[300],
		borderRightStyle: 'solid',
		color: grey[700],
	},
	dateCell: {
		borderRightWidth: 1,
		borderRightColor: grey[300],
		borderRightStyle: 'solid',
		verticalAlign: 'top',
	},
	inputCell: {
		padding: theme.spacing(0.85, 2),
	},
	inputCellDiff: {
		color: grey[500],
	},
}));

function printValueDiff(value){
	let sign = (value >= 0)? '+': '';
	value = Math.round(value * 10) / 10;
	return ` ${sign}${value}`;
}

function VectorRow({ date, dateRows, hour, data }){
	const classes = useStyles();

	return (
		<TableRow>
			{(dateRows)&&(
				<TableCell className={classes.dateCell} rowSpan={dateRows}>
					<Typography noWrap variant="caption">{ date }</Typography>
				</TableCell>
			)}
			<TableCell className={classes.hourCell}>
				{ hour }
			</TableCell>
			<TableCell className={classes.inputCell}>
				{data.coal}
				{(data.coal_measured != null)&&(
					<span className={classes.inputCellDiff}>{ printValueDiff(data.coal_measured - data.coal) }</span>
				)}
			</TableCell>
			<TableCell className={classes.inputCell}>
				{data.oil}
				{(data.oil_measured != null)&&(
					<span className={classes.inputCellDiff}>{ printValueDiff(data.oil_measured - data.oil) }</span>
				)}
			</TableCell>
			<TableCell className={classes.inputCell}>
				{data.caoh_dos}
				{(data.caoh_dos_measured != null)&&(
					<span className={classes.inputCellDiff}>{ printValueDiff(data.caoh_dos_measured - data.caoh_dos) }</span>
				)}
			</TableCell>
			<TableCell className={classes.inputCell}>
				{data.temperature}
				{(data.temperature_measured != null)&&(
					<span className={classes.inputCellDiff}>{ printValueDiff(data.temperature_measured - data.temperature) }</span>
				)}
			</TableCell>
			<TableCell className={appendClasses(classes.inputCell, classes.inputCellLast)}>
				{data.rate}
				{(data.rate_measured != null)&&(
					<span className={classes.inputCellDiff}>{ printValueDiff(data.rate_measured - data.rate) }</span>
				)}
			</TableCell>
		</TableRow>
	);
};

VectorRow.propTypes = {
	data: PropTypes.object.isRequired,
	hour: PropTypes.string.isRequired,
	//lastItem: PropTypes.bool,
	date: PropTypes.string,
	dateRows: PropTypes.number,
};

export default VectorRow;