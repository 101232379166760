import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from "react-hook-form";
import { useSession } from '../../contexts/SessionContext.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../../assets/logo-white.svg';
import logoMatcom from '../../assets/logo-matcom.svg';
import './Login.scss';

const useStyles = makeStyles({
	root: {
		maxWidth: 500,
	},
	actionsContainer: {
		justifyContent: 'flex-end',
	},
	field: {
		margin: '0.8em 0 0 0',
		width: '100%',
	},
	form: {
		width: '100%',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

function LoginFormCard() {
	const history = useHistory();
	const { session, api, login, hasPermission } = useSession();
	const classes = useStyles();
	//const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { handleSubmit, control, errors, clearError } = useForm();

	const [loading, setLoading] = useState(false);
	const onSubmit = function onSubmit(values){
		clearError(['username', 'password']);

		setLoading(true);
		api.post('/login', values).then((data) => login(data)).catch(err => {
			setLoading(false);
		});
	}

	return (
		<div>
			<Card className={classes.root}>
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<CardContent>
						<Typography variant="h5" component="h3">Iniciar Sesi&oacute;n</Typography>
						<Controller
							as={TextField}
							name="username"
							autoComplete="username"
							control={control}
							rules={{
								required: "Se requiere un nombre de usuario!",
								pattern: {
									value: /^([a-zA-Z-0-9]+)(\.[a-zA-Z-0-9]+)*$/i,
									message: "Se requiere un nombre de usuario válido!"
								}
							}}
							defaultValue=""
							disabled={loading}
							label="Nombre de Usuario"
							placeholder="nombre.apellido"
							variant="outlined"
							size="small"
							className={classes.field}
							error={!!errors.username}
							helperText={errors.username && errors.username.message}
						/>
						<Controller
							as={TextField}
							name="password"
							autoComplete="current-password"
							control={control}
							rules={{ required: true }}
							defaultValue=""
							disabled={loading}
							type="password"
							label="Contrase&ntilde;a"
							variant="outlined"
							size="small"
							className={classes.field}
							error={!!errors.password}
							helperText={errors.password && "Se requiere una contraseña!"}
						/>
					</CardContent>
					<CardActions className={classes.actionsContainer}>
						<Button color="primary" type="submit" disabled={loading}>
							Entrar
							<ExitToAppIcon />
							{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						</Button>
					</CardActions>
				</form>
			</Card>
		</div>
	);
};

export default function Login() {
	return (
		<section className="view-login">
			<main>
				<div className="logo">
					<img src={logo} draggable="false" alt="Logo CMP" />
				</div>
				<LoginFormCard />
				<Card className="copy">
					<CardContent className="copy-content">
						Desarrollado por <img className="logo" src={logoMatcom} draggable="false" alt="Logo MatCom" /> - 2020
					</CardContent>
				</Card>
			</main>
		</section>
	);
};