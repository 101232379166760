import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import TitledRoute from '../../components/Router/TitledRoute.jsx';

import Create from '../Planification/Create.jsx';
import ShowRouter from '../Planification/Show/Router.jsx';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
    }
}));

function PlanificationRouter(){
    const { path } = useRouteMatch();
    const classes = useStyles();
    return (
        <Switch>
            <TitledRoute path={`${path}/create`} exact title="Crear Planificación">
                <Create />
            </TitledRoute>
            <TitledRoute path={`${path}/:id/create`} exact title="Crear Planificación">
                <Create />
            </TitledRoute>
            <Route path={`${path}/:planificationId`}>
                <ShowRouter />
            </Route>
        </Switch>
    );
};

export default PlanificationRouter;