import React from 'react';

export const usePersistentState = (name, data) => {
    const cacheData = localStorage.getItem(name);
    if(cacheData != null){
        const cached = JSON.parse(cacheData);
        data = Object.assign({}, data, cached);
    }
    const [value, setValue] = React.useState(data);
    
    React.useEffect(() => {
        localStorage.setItem(name, JSON.stringify(value));
    }, [value]);

    const unset = () => localStorage.removeItem(name);

    return [value, setValue, unset];
};
