import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import './Toolbar.scss';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ToolbarDatePicker from './DatePicker.jsx';

import { preventDefault } from './utils.js';

const useStyles = makeStyles((theme) => ({
	root: {
        height: theme.spacing(6),
    },
    inputNumber: {
        paddingRight: theme.spacing(1),
        '& input': {
            fontSize: 13,
            padding: '8px 10px',
            display: 'inline-block',
            width: 60,
        }
    }
}));

function Toolbar({ loading, onSave, onErase, onCopy, onAddRow, onInsertRow, onRemove, onChangeDate, startDate, endDate, isUnfocus, toolbar, maxEndDate })
{
    const classes = useStyles();
    const [quantity, setQuantity] = React.useState(1);

    return (
        <div className={clsx(classes.root, 'toolbar')}>
        <div className={clsx("toolbar-section", (toolbar == 'save') && "show")}>
            <div className="action">
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => onSave()}
                >
                    Guardar
                </Button>
            </div>
            <div className="action">
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => onSave(true)}
                >
                    Guardar y Simular
                </Button>
            </div>
        </div>
            <div className={clsx("toolbar-section", (toolbar == 'dates') && "show")}>
                <ToolbarDatePicker
                    disabled={loading || maxEndDate == null}
                    startDate={startDate}
                    endDate={endDate}
                    maxEndDate={maxEndDate}
                    onChange={onChangeDate}
                />
            </div>
            <div className={clsx('toolbar-section', 'rows', (toolbar == 'rows') && "show")}>
                <div className="quantity">
                    <TextField
                        label="Size"
                        type="number"
                        id="outlined-size-normal"
                        variant="outlined"
                        onChange={(event) => setQuantity(parseInt(event.target.value))}
                        value={quantity}
                        disabled={loading}
                        className={classes.inputNumber}
                        InputProps={{ inputProps: { min: 1, step: 1 } }}
                    />
                </div>
                <div className="action">
                    <Button
                        size="small"
                        disabled={loading || typeof(quantity) != 'number' || isNaN(quantity)}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onMouseDown={preventDefault}
                        onClick={() => onAddRow(quantity)}
                    >
                        Agregar {(isNaN(quantity)? 0: quantity)} fila
                    </Button>
                </div>
                <div className="action">
                    <Button
                        size="small"
                        disabled={loading || typeof(quantity) != 'number' || isNaN(quantity)}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onMouseDown={preventDefault}
                        onClick={() => onInsertRow(quantity)}
                    >
                        Insertar {(isNaN(quantity)? 0: quantity)} fila
                    </Button>
                </div>
                <div className="action">
                    <Button
                        size="small"
                        disabled={loading || isUnfocus}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onMouseDown={preventDefault}
                        onClick={onRemove}
                    >
                        Eliminar fila
                    </Button>
                </div>
                <div className="action">
                    <Button
                        size="small"
                        disabled={loading || isUnfocus}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onMouseDown={preventDefault}
                        onClick={() => onCopy()}
                    >
                        Copiar hacia abajo
                    </Button>
                </div>
                <div className="action">
                    <Button
                        size="small"
                        disabled={loading || isUnfocus}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onMouseDown={preventDefault}
                        onClick={onErase}
                    >
                        Resetear fila
                    </Button>
                </div>
            </div>
        </div>
    )
};

Toolbar.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onAddRow: PropTypes.func.isRequired,
    onInsertRow: PropTypes.func.isRequired,
    onErase: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    onChangeDate: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    maxEndDate: PropTypes.instanceOf(Date),
    isUnfocus: PropTypes.bool.isRequired,
    toolbar: PropTypes.string.isRequired,
};

export default Toolbar;