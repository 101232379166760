import React, { useEffect, useReducer, useCallback } from 'react';
import { useTimelapse } from '../../contexts/TimelapseContext.js';
import useStyles from './Timelapse.styles.js';
import { withStyles } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DatePicker from '../../components/Timelapse/DatePicker.jsx';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import TimelapseViewer from '../../components/Timelapse/TimelapseViewer.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import CardLoading from '../../components/CardLoading/CardLoading.jsx';
import ListVideos from '../../components/Timelapse/ListVideos.jsx';

const setCustomTime = (date, hour, minute) => {
    let copy = new Date(date.getTime());
    copy.setHours(hour);
    copy.setMinutes(minute);
    copy.setSeconds(0);
    copy.setMilliseconds(0);
    return copy;
};

const today = setCustomTime(new Date, 0, 0);

// const instants = [
//     {
//         label: '00:00 - 05:55',
//         start: date => setCustomTime(date, 0, 0),
//         end: date => setCustomTime(date, 5, 55),
//     },
//     {
//         label: '06:00 - 11:55',
//         start: date => setCustomTime(date, 6, 0),
//         end: date => setCustomTime(date, 11, 55),
//     },
//     {
//         label: '12:00 - 17:55',
//         start: date => setCustomTime(date, 12, 0),
//         end: date => setCustomTime(date, 17, 55),
//     },
//     {
//         label: '18:00 - 23:55',
//         start: date => setCustomTime(date, 18, 0),
//         end: date => setCustomTime(date, 23, 55),
//     },
// ];

const instants = [
    {
        label: '20:00 - 06:55',
        start: date => {
            date = setCustomTime(date, 20, 0);
            date.setDate(date.getDate() - 1);
            return date;
        },
        end: date => setCustomTime(date, 6, 55),
    },
    {
        label: '07:00 - 19:55',
        start: date => setCustomTime(date, 7, 0),
        end: date => setCustomTime(date, 19, 55),
    },
];

const CustomSelectInput = withStyles((theme) => ({
    root: {
        '& .MuiSelect-icon': {
            color: 'white',
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        fontSize: '0.8125rem',
        padding: '6px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            backgroundColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

const INITIAL_STATE = {
    date: today,
    camera: null,
    cameraIndex: 0,
    instant: null,
    instantIndex: -1,
    frame: null,
    frameIndex: 0,
    dialogOpen: false,
    dialogStartDate: null,
    dialogEndDate: null,
    dialogSelectedStartDate: null,
    dialogSelectedEndDate: null,
};

const actions = {
    CHANGE_VIEW: Symbol.for('CHANGE_VIEW'),
    CHANGE_FRAME: Symbol.for('CHANGE_FRAME'),
    SHOW_DIALOG: Symbol.for('SHOW_DIALOG'),
    HIDE_DIALOG: Symbol.for('HIDE_DIALOG'),
    SELECT_START_DATE: Symbol.for('SELECT_START_DATE'),
    SELECT_END_DATE: Symbol.for('SELECT_END_DATE'),
    SET_DATES: Symbol.for('SET_DATES'),
}

const reducer = (state, action) => {
    switch(action.type){
        case actions.CHANGE_VIEW:
            let instant = state.instant;
            let instantIndex = state.instantIndex;
            if('instant' in action){
                if(action.instant < instants.length){
                    instant = instants[action.instant];
                    instantIndex = action.instant;
                }
                else{
                    instant = null;
                    instantIndex = -1;
                }
            }
            return {
                ...state,
                date: ('date' in action)? action.date: state.date,
                camera: ('camera' in action)? action.camera: state.camera,
                cameraIndex: ('cameraIndex' in action)? action.cameraIndex: state.cameraIndex,
                instant: instant,
                instantIndex: instantIndex,
                frame: null,
                frameIndex: 0,
            }
        case actions.CHANGE_FRAME:
            return {
                ...state,
                frame: action.frame,
                frameIndex: action.frameIndex,
            }
        case actions.SHOW_DIALOG:
            if(state.dialogOpen === false){
                return {
                    ...state,
                    dialogOpen: true,
                    dialogSelectedStartDate: state.dialogStartDate,
                    dialogSelectedEndDate: state.dialogEndDate,
                }
            }
            return state;
        case actions.HIDE_DIALOG:
            if(state.dialogOpen === true){
                return {
                    ...state,
                    dialogOpen: false,
                    dialogSelectedStartDate: state.dialogStartDate,
                    dialogSelectedEndDate: state.dialogEndDate,
                }
            }
            return state;
        case actions.SELECT_START_DATE:
            if(action.date.getTime() !== state.dialogSelectedStartDate.getTime()){
                return {
                    ...state,
                    dialogSelectedStartDate: action.date,
                }
            }
            return state;
        case actions.SELECT_END_DATE:
            if(action.date.getTime() !== state.dialogSelectedEndDate.getTime()){
                return {
                    ...state,
                    dialogSelectedEndDate: action.date,
                }
            }
            return state;
        case actions.SET_DATES:
            return {
                ...state,
                dialogStartDate: action.start,
                dialogEndDate: action.end,
            }
        default:
            return state;
    }
};

function Timelapse() {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const {
        camerasLoading,
        cameras,
        framesLoading,
        frames,
        framesLoaded,
        videos,
        videosLoading,
        makingVideo,
        taskData,
        fetchFrames,
        downloadVideo,
        createVideo,
    } = useTimelapse();
    const classes = useStyles();

    useEffect(() => {
        if(!!frames && frames.length > 0){
            const first = frames[0];
            const last = frames[frames.length - 1];
            dispatch({ type: actions.SET_DATES, start: first.date, end: last.date });
        }
    }, [frames]);

    useEffect(() => {
        if(!camerasLoading && cameras.length > 0){
            dispatch({ type: actions.CHANGE_VIEW, camera: cameras[state.cameraIndex] });
        }
    }, [camerasLoading, cameras]);

    useEffect(() => {
        if(!!state.date && !!state.instant && !!state.camera){
            const startDate = state.instant.start(state.date);
            const endDate = state.instant.end(state.date);
            fetchFrames(startDate, endDate, state.camera.id);
        }
    }, [state.date, state.instant, state.instantIndex, state.camera]);

    const handleChangeInstant = useCallback((event) => {
        let newInstant = event.target.value;
        if(newInstant >= 0 && newInstant < instants.length){
            dispatch({ type: actions.CHANGE_VIEW, instant: newInstant });
        }
    }, [dispatch]);

    const handleChangeCamera = useCallback(event => {
        const index = event.target.value;
        dispatch({ type: actions.CHANGE_VIEW, camera: cameras[index], cameraIndex: index, });
    }, [cameras, dispatch]);

    const handleChangeDate = useCallback((date) => {
        dispatch({ type: actions.CHANGE_VIEW, date });
    }, [dispatch]);

    const handleFrameChange = useCallback((frameIndex) => {
        if(frameIndex >= 0 && frameIndex < frames.length){
            const frame = frames[frameIndex]
            dispatch({ type: actions.CHANGE_FRAME, frame, frameIndex });
        }
    }, [frames, dispatch]);

    const handleCreateVideo = useCallback(() => {
        if(!camerasLoading && !framesLoading && !!state.instant){
            dispatch({ type: actions.SHOW_DIALOG });
        }
    }, [state, camerasLoading, framesLoading, dispatch]);

    const handleDialogClose = useCallback(() => dispatch({ type: actions.HIDE_DIALOG }), [dispatch]);

    const handleDialogOk = useCallback(() => {
        if(!!state.camera && !!state.dialogStartDate && !!state.dialogStartDate){
            createVideo(state.dialogStartDate, state.dialogEndDate, state.camera.id);
            dispatch({ type: actions.HIDE_DIALOG });
        }
    }, [state, createVideo, dispatch]);

    const handleDialogStartDateChange = useCallback((event) => {
        dispatch({ type: actions.SELECT_START_DATE, date: event.target.value });
    }, [dispatch]);

    const handleDialogEndDateChange = useCallback((event) => {
        dispatch({ type: actions.SELECT_END_DATE, date: event.target.value });
    }, [dispatch]);

    const dialogStartProps = {};
    if(state.dialogStartDate !== null) dialogStartProps.value = state.dialogStartDate;
    const dialogEndProps = {};
    if(state.dialogEndDate !== null) dialogEndProps.value = state.dialogEndDate;

    return (
        <section className={classes.root}>
            <Card className={classes.viewerCard}>
                <CardContent>
                    <div className={classes.buttons}>
                        <div className={classes.buttonItem}>
                            <DatePicker
                                onChange={handleChangeDate}
                                date={state.date}
                            />
                        </div>
                        <div className={classes.buttonItem}>
                            {(camerasLoading)?(
                                <Button
                                    size="small"
                                    variant="contained"
                                    disableElevation
                                    disabled
                                    color="primary"
                                >
                                    Cargando...
                                </Button>
                            ):(
                                <FormControl className={classes.formControl}>
                                    <Select
                                        disabled={camerasLoading || framesLoading}
                                        labelId="instant-select-label"
                                        id="instant-select"
                                        defaultValue={0}
                                        value={state.cameraIndex}
                                        onChange={handleChangeCamera}
                                        input={<CustomSelectInput />}
                                    >
                                        {cameras.map((item, index) => (
                                            <MenuItem key={item.id} value={index}>{ item.name }</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <div className={classes.buttonItem}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    disabled={camerasLoading || framesLoading}
                                    labelId="instant-select-label"
                                    id="instant-select"
                                    defaultValue={-1}
                                    value={state.instantIndex}
                                    onChange={handleChangeInstant}
                                    input={<CustomSelectInput />}
                                >
                                    <MenuItem value={-1} disabled>Seleccionar Instante</MenuItem>
                                    {instants.map((instant, index) => (
                                        <MenuItem key={index} value={index}>{ instant.label }</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.buttonItemRight}>
                            {makingVideo?(
                                <Button
                                    size="small"
                                    variant="contained"
                                    disableElevation
                                    disabled
                                    color="primary"
                                    startIcon={<MovieFilterIcon />}
                                >
                                    Creando Video: {taskData.progress}%
                                </Button>
                            ):(
                                <Button
                                    size="small"
                                    variant="contained"
                                    disableElevation
                                    disabled={camerasLoading || framesLoading || !state.instant || !framesLoaded}
                                    color="primary"
                                    startIcon={<MovieFilterIcon />}
                                    onClick={handleCreateVideo}
                                >
                                    Crear Video
                                </Button>
                            )}
                        </div>
                    </div>
                    <TimelapseViewer
                        loading={camerasLoading}
                        framesLoading={framesLoading}
                        frames={frames}
                        currentFrame={state.frameIndex}
                        onFrameChange={handleFrameChange}
                        isInstantSelected={!!state.instant}
                    />
                </CardContent>
            </Card>
            {videosLoading?(
                <CardLoading text="Cargando Videos..." />
            ):(
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Videos:
                        </Typography>
                        <ListVideos
                            videos={videos}
                            onDownload={downloadVideo}
                        />
                    </CardContent>
                </Card>
            )}
            <Dialog open={state.dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Selecciona los Instantes:</DialogTitle>
                <DialogContent>
                    <form className={classes.dialogContainer}>
                        <FormControl className={classes.dialogFormControl}>
                            <InputLabel htmlFor="dialog-start-select-label">Inicio</InputLabel>
                            <Select
                                labelId="dialog-start-select-label"
                                id="dialog-start-select"
                                value={state.dialogSelectedStartDate}
                                onChange={handleDialogStartDateChange}
                                input={<Input />}
                            >
                                {frames.map((frame, index) => (
                                    <MenuItem
                                        key={index}
                                        value={frame.date}
                                        disabled={frame.date >= state.dialogSelectedEndDate}
                                    >
                                        { frame.time } 
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.dialogFormControl}>
                            <InputLabel id="dialog-end-select-label">Término</InputLabel>
                            <Select
                                labelId="dialog-end-select-label"
                                id="dialog-end-select"
                                value={state.dialogSelectedEndDate}
                                onChange={handleDialogEndDateChange}
                                input={<Input />}
                            >
                                {frames.map((frame, index) => (
                                    <MenuItem
                                        key={index}
                                        value={frame.date}
                                        disabled={frame.date <= state.dialogSelectedStartDate}
                                    >
                                        { frame.time }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </section>
    );
}

Timelapse.propTypes = {};

export default Timelapse;