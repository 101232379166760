import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import AuthRoute from '../Router/AuthRoute.jsx';
import NotAuthRoute from '../Router/NotAuthRoute.jsx';
import Login from '../../views/Login/Login.jsx';
import Main from '../../views/Main/Main.jsx';

import { SessionProvider } from '../../contexts/SessionContext.js';
import { WebSocketProvider } from '../../contexts/WebSocketContext.js';

export default function App() {
	return (
		<div id="app">
			<SnackbarProvider maxSnack={4} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
				<SessionProvider>
					<WebSocketProvider>
						<BrowserRouter>
							<Switch>
								<NotAuthRoute
									path='/login'
									exact
									component={Login}
									title="Login"
								/>
								<AuthRoute
									path="/"
									disableTitle
									component={Main}
								/>
							</Switch>
						</BrowserRouter>
					</WebSocketProvider>
				</SessionProvider>
			</SnackbarProvider>
		</div>
	);
};