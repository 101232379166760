import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const inputFormat = "dd MMMM yyyy";

const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octube',
    'Noviembre',
    'Diciembre'
];

const today = new Date;
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

const CustomContainer = ({ children }) => {
    return (
        <Box className="react-datepicker" boxShadow={4}>
            {children}
        </Box>
    );
};

CustomContainer.propTypes = {
    children: PropTypes.any,
};

const renderDayContents = (day) => {
    return (
        <div className="box-day-wrapper">
            <div className="box-day">{day}</div>
        </div>
    );
};

const CustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
    <div className="react-datepicker__current-month">
        <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--previous react-datepicker__navigation--previous--with-time"
            aria-label="Mes Anterior"
            disabled={prevMonthButtonDisabled}
            onClick={decreaseMonth}
        >
            Mes Anterior
        </button>
        <div className="text">{ months[date.getMonth()] } { date.getFullYear() }</div>
        <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--next react-datepicker__navigation--next--with-time"
            aria-label="Mes Siguiente"
            disabled={nextMonthButtonDisabled}
            onClick={increaseMonth}
        >
            Mes Siguiente
        </button>
    </div>
);
CustomHeader.propTypes = {
    date: PropTypes.any,
    decreaseMonth: PropTypes.any,
    increaseMonth: PropTypes.any,
    prevMonthButtonDisabled: PropTypes.any,
    nextMonthButtonDisabled: PropTypes.any
};

function TimelapseDatePicker({ disabled = false, date, onChange })
{
    const [visibility, setVisibility] = React.useState(false);
    const handleClick = (evt) => {
        evt.preventDefault();
        setVisibility(!visibility);
    };
    const handleChange = (value) => {
        setVisibility(false);
        onChange(value)
    };

    return (
        <div className="react-datepicker-container">
            <Button
                size="small"
                disabled={disabled}
                color="primary"
                variant="contained"
                disableElevation
                onClick={handleClick}
            >
                {date.getDate()} { months[date.getMonth()] } { date.getFullYear() }
            </Button>
            <div className={clsx('react-datepicker-toggle', visibility && 'show')}>
                <DatePicker
                    inline
                    dateFormat={inputFormat}
                    closeOnScroll={true}
                    selected={date}
                    disabled={disabled}
                    maxDate={today}
                    renderDayContents={renderDayContents}
                    calendarContainer={CustomContainer}
                    renderCustomHeader={CustomHeader}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

TimelapseDatePicker.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default TimelapseDatePicker;