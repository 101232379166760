import concentracionesValores from '../../../utils/concentraciones.js';
import moment from '../../../utils/moment.js';

export function assignAmounts(vectors){
	let criticalPoints = [];

	vectors.forEach((vector, index) => {
		if(vector.change) criticalPoints.push(index);
	});

	criticalPoints.forEach((point, index, list) => {
		let next = index + 1;
		if(next < list.length){
			vectors[point].amount = list[next] - point;
		}
		else{
			vectors[point].amount = vectors.length - point;
		}
	});
};

export function zeropad(value, size = 3){
	let str = value.toString();
	if(str.length < size){
		let diff = size - str.length;
		for(let i = 0; i < diff; ++i) str = '0' + str;
	}
	return str;
};

export function parseColor(value, type){
	if(type == 'mp' || type == 'no' || type == 'so'){
		if(value > concentracionesValores[type].bad){
			return 'red';
		}
		else if(value > concentracionesValores[type].regular){
			return 'yellow';
		}
		return 'green';
	}
	return null;
};

export function generateInstants(vectors){
	let dates = vectors.map(vector => ({
		date: vector.span,
		text_date: moment(vector.span).format('ll'),
		text_time: moment(vector.span).format('HH:mm'),
		change: false,
		amount: 0,
	}));

	let lastDate = null;
	let criticalPoints = [];
	dates.forEach((date, index) => {
		if(index == 0 || (lastDate.getDay() != date.date.getDay())){
			criticalPoints.push(index);
			dates[index].change = true;
		}
		lastDate = date.date;
	});

	criticalPoints.forEach((point, index, list) => {
		let next = index + 1;
		if(next < list.length){
			dates[point].amount = list[next] - point;
		}
		else{
			dates[point].amount = dates.length - point;
		}
	});

	return dates;
};