import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const DRAWER_WIDTH = 240;
const DEFAULT_EM = 16;

export default makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	appBar: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,
		marginLeft: DRAWER_WIDTH,
	},
	toolbar: theme.mixins.toolbar,
	cardRoot: {
		marginBottom: theme.spacing(2),
	},
	cardTitle: {
		fontSize: 14,
	},
	cardPos: {
		marginBottom: 12,
	},
	searchBox: {
		padding: theme.spacing(1),
		marginBottom: theme.spacing(2),
		backgroundColor: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		borderRadius: theme.spacing(0.5),
	},
	searchIcon: {
		marginLeft: 0.5*DEFAULT_EM,
		marginRight: 1*DEFAULT_EM,
	},
	searchInput: {
		width: '100%',
		marginLeft: theme.spacing(1),
	},
	loading: {
		textAlign: 'center',
		color: theme.palette.primary.main,
		padding: theme.spacing(8) + 'px !important',
	},
	tableRoot: {
		width: '100%',
	},
	tableHeaderCell: {
		top: theme.spacing(8),
		backgroundColor: '#fff',
	},
	tableHeaderCellFirst: {
		top: theme.spacing(8),
		backgroundColor: '#fff',
		width: 62,
	},
	infoAlert: {
		marginBottom: theme.spacing(1),
	},
	btnActionLeft: {
		marginRight: '0.5em',
	},
	previewChip: {
		minWidth: 160,
		maxWidth: 210
	},
	dropzoneContainer: {
		marginBottom: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& .MuiDropzoneArea-textContainer': {
			padding: 2,
		}
	},
	dropzoneParagraph: {
		fontSize: '1em',
	},
	dropzoneParagraphText: {
		fontSize: '1.4em',
	},
	dropzoneParagraphTip: {
		color: grey[600],
	},
}));