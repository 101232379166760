import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TimelapseProvider } from '../../contexts/TimelapseContext.js';
import TitledRoute from '../../components/Router/TitledRoute.jsx';
import Home from '../Home/Home.jsx';
import Planifications from '../Planification/List.jsx';
import PlanificationRouter from '../Planification/Router.jsx';
import UserProfile from '../UserProfile/UserProfile.jsx';
import Timelapse from '../Timelapse/Timelapse.jsx';
import Layout from '../../components/Layout/Layout.jsx';
import { useSession } from '../../contexts/SessionContext.js';

function SwitchRoute(props){
    const { session, hasPermission } = useSession();
    let child;
    if(session.admin || hasPermission('access_timelapse')){
        child = (<Redirect to='/timelapse' />);
    }
    else if(session.admin || hasPermission('view_planification')){
        child = (<Redirect to='/planifications' />);
    }
    else{
        child = (<Redirect to='/home' />);
    }
    return (
        <Route {...props}>{child}</Route>
    );
}
SwitchRoute.propTypes = {};

function Main(){
    return (
		<Layout>
            <Switch>
                {/* <TitledRoute path="/home" exact title="Home">
                    <Home />
                </TitledRoute> */}
                <TitledRoute path="/profile" exact title="UserProfile">
                    <UserProfile />
                </TitledRoute>
                <TitledRoute path="/timelapse" exact title="Timelapse">
                    <TimelapseProvider>
                        <Timelapse />
                    </TimelapseProvider>
                </TitledRoute>
                <TitledRoute path="/planifications" exact title="Planificaciones">
                    <Planifications />
                </TitledRoute>
                <Route path="/planification">
                    <PlanificationRouter />
                </Route>
                <SwitchRoute path="/" exact />
            </Switch>
        </Layout>
    )
};

export default Main;