import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import { useSession } from './SessionContext.js';

const IS_DEV = (process.env.NODE_ENV == 'development');

export const WebSocketContext = React.createContext({});

const log_debug = (...args) => {
    if(IS_DEV) console.debug(...args);
}

export const useSocket = () => useContext(WebSocketContext);

export function WebSocketProvider(props){
    const { session } = useSession();

    const wsData = useRef({
        instance: null,
        on(...args){
            this.instance.addEventListener(...args)
        },
        off(...args){
            this.instance.removeEventListener(...args)
        },
    });

    useEffect(() => {
        let wsInstance;
        if(session.isAuthenticated == true && wsData.current.instance == null){
            let wsProtocol = (window.location.protocol == 'https:')? 'wss': 'ws';
            let wsHost = IS_DEV? 'localhost:8000': window.location.host;
            wsInstance = new WebSocket(`${wsProtocol}://${wsHost}/channel/planifications`);
            
            wsInstance.addEventListener('open', () => log_debug('WebSocket Connected!'));
            wsInstance.addEventListener('close', () => log_debug('WebSocket Closed!'));
            wsInstance.addEventListener('error', err => log_debug('WebSocket caused an error!', err));

            wsData.current.instance = wsInstance;
        }

        return () => {
            if(wsData.current.instance != null){
                wsInstance.close();
                wsData.current.instance = null;
            }
        }
    }, [session]);

    return (
        <WebSocketContext.Provider value={wsData.current}>
            {props.children}
        </WebSocketContext.Provider>
    );
};
WebSocketProvider.propTypes = {
	children: PropTypes.any.isRequired,
};