import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import MpInfo from '../../../components/Planification/MpInfo.jsx';


const useStyles = makeStyles((theme) => ({
	cardContainer: {
		marginBottom: theme.spacing(2),
	},
}));

function Statistics({ planification }){
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.cardContainer}>
                <MpInfo
                    vectors={planification.vectors}
                    title="Material Particulado"
                    subtitle="Material Particulado"
                    labelMaterialShort="MP"
                    fieldScrubber="mp_scrubber"
                    fieldPrecipitator="mp_precipitator"
                />
            </div>
            <div className={classes.cardContainer}>
                <MpInfo
                    vectors={planification.vectors}
                    label="Planificado"
                    title={<>SO<sub>2</sub></>}
                    subtitle="Dióxido de Azufre"
                    labelMaterialShort="SO"
                    fieldScrubber="so_scrubber"
                    fieldPrecipitator="so_precipitator"
                />
                </div>
            <div className={classes.cardContainer}>
                <MpInfo
                    vectors={planification.vectors}
                    label="Planificado"
                    title="NOX"
                    subtitle="Óxidos de Nitrógeno"
                    labelMaterialShort="NO"
                    fieldScrubber="no_scrubber"
                    fieldPrecipitator="no_precipitator"
                />
            </div>
        </React.Fragment>
    );
}

Statistics.propTypes = {
	planification: PropTypes.object.isRequired,
};

export default Statistics;