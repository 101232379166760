import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';

import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const styles = theme => ({
	videosEmtpyMessage: {
		textAlign: 'center',
		padding: '3em',
		color: 'grey',
	},
	videosItemLabel: {
		marginRight: theme.spacing(1),
	},
});

function ListVideos({ videos, classes, onDownload }){
    if(videos.length == 0){
        return (
            <div className={classes.videosEmtpyMessage}>No hay Videos!</div>
        );
    }
    return (
        <List>
            {videos.map(video => (
                <ListItem key={video.id} button>
                    <ListItemText primary={
                        <React.Fragment>
                            <Chip
                                className={classes.videosItemLabel}
                                color="primary"
                                label={video.startTime}
                                size="small"
                            />
                            <IconButton size="small" className={classes.videosItemLabel} disabled>
                                <ArrowRightAltIcon color="primary" />
                            </IconButton>
                            <Chip
                                color="primary"
                                label={video.endTime}
                                size="small"
                            />
                        </React.Fragment>
                    }/>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="descargar" onClick={() => onDownload(video)}>
                            <GetAppIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

ListVideos.propTypes = {
    classes: PropTypes.object.isRequired,
    videos: PropTypes.array.isRequired,
    onDownload: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListVideos);