import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

function TitledRoute({ title = null, ...rest }){
	if(title) document.title = 'Oftuku - ' + title;
	else document.title = 'Oftuku';

	return (
		<Route {...rest} />
	);
}
TitledRoute.propTypes = {
	title: PropTypes.string,
};

export default TitledRoute;