import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: '#333',
		position: 'relative',

		// padding: theme.spacing(1),
		// marginBottom: theme.spacing(2),
		// alignItems: 'center',
		// justifyContent: 'flex-start',
		// borderRadius: theme.spacing(0.5),
	},
	toolbar: {
		backgroundColor: 'rgba(0,0,0,.8)',
		padding: theme.spacing(0, 1.5, 1.5, 1.5),
		color: '#fff',
		zIndex: 110,
	},
	toolbarOptions: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	slider: {
		marginTop: (-1)*theme.spacing(1.6),
	},
	tabsRoot: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		zIndex: 110,
	},
	navButton: {
		color: '#fff',
		margin: theme.spacing(0, 0.5, 0, 0),
	},
	loading: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		top: theme.spacing(6),
		left: 0,
		right: 0,
		bottom: theme.spacing(6),
		backgroundColor: 'rgba(0,0,0,0.6)',
		color: 'white',
		zIndex: 100,
		userSelect: 'none',
	},
	viewerElement: {
		position: 'relative',
		width: '100%',
		height: 600,
		overflow: 'hidden',
	},
	// canvasElement: {
	// 	position: 'absolute',
	// 	top: 0,
	// 	left: 0,
	// 	right: 0,
	// 	bottom: 0,
	// 	zIndex: 100,
	// 	width: '100%',
	// 	height: '100%',
	// }
}));