import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    cardTableContent: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    cardHeader: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    borderRightCell: {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
	subtitle: {
		color: grey[700],
	},
}));

function round(num, decimals){
    let base = 10 ** decimals;
    return Math.round(num * base) / base;
}

function addCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const printValue = (value) => {
    let rounded = round(value, 2);
    if(typeof(value) == 'number' && !isNaN(value)) return addCommas(rounded);
    return '-';
};

function MpInfo({ vectors, title, subtitle, labelMaterialShort, fieldScrubber, fieldPrecipitator }){
    const classes = useStyles();

    const fieldScrubberPlanificated = fieldScrubber;
    const fieldScrubberCalculated = fieldScrubber + '_calculated';
    const fieldScrubberMeasured = fieldScrubber + '_measured';

    const fieldPrecipitatorPlanificated = fieldPrecipitator;
    const fieldPrecipitatorCalculated = fieldPrecipitator + '_calculated';
    const fieldPrecipitatorMeasured = fieldPrecipitator + '_measured';

    return (
        <Card>
            {subtitle?(
                <CardHeader
                    className={classes.cardHeader}
                    title={title}
                    subheader={
                        <Typography variant="caption" className={classes.subtitle}>{ subtitle }</Typography>
                    }
                />
            ):(
                <CardHeader
                    className={classes.cardHeader}
                    title={title}
                />
            )}
            <CardContent className={classes.cardTableContent}>
                <TableContainer>
                    <Table className={classes.table} aria-label="mp info table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.borderRightCell} />
                                <TableCell className={classes.borderRightCell} component="th" colSpan={3} align="center">{labelMaterialShort} Precipitador 1B [&micro;g/m<sup>3</sup>]</TableCell>
                                <TableCell component="th" colSpan={3} align="center">{labelMaterialShort} Precipitador 1A [mg/m<sup>3</sup>N]</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.borderRightCell} width={130} align="center">Fecha</TableCell>
                                <TableCell component="th" align="center">Planificado</TableCell>
                                <TableCell component="th" align="center">Calculado</TableCell>
                                <TableCell className={classes.borderRightCell} component="th" align="center">Medido</TableCell>
                                <TableCell component="th" align="center">Planificado</TableCell>
                                <TableCell component="th" align="center">Calculado</TableCell>
                                <TableCell component="th" align="center">Medido</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vectors.map((vector) => (
                                <TableRow key={vector.span}>
                                    <TableCell className={classes.borderRightCell} align="center">{vector.short_date}</TableCell>
                                    <TableCell align="center">{printValue(vector.fields[fieldScrubberPlanificated])}</TableCell>
                                    <TableCell align="center">{printValue(vector.fields[fieldScrubberCalculated])}</TableCell>
                                    <TableCell className={classes.borderRightCell} align="center">{printValue(vector.fields[fieldScrubberMeasured])}</TableCell>
                                    <TableCell align="center">{printValue(vector.fields[fieldPrecipitatorPlanificated])}</TableCell>
                                    <TableCell align="center">{printValue(vector.fields[fieldPrecipitatorCalculated])}</TableCell>
                                    <TableCell align="center">{printValue(vector.fields[fieldPrecipitatorMeasured])}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}

MpInfo.propTypes = {
    vectors: PropTypes.array.isRequired,
    title: PropTypes.any.isRequired,
    subtitle: PropTypes.string,
    labelMaterialShort: PropTypes.string.isRequired,
    fieldScrubber: PropTypes.string.isRequired,
    fieldPrecipitator: PropTypes.string.isRequired,
};

export default MpInfo;