import React from 'react';
import PropTypes from 'prop-types';
import { useSession } from '../../contexts/SessionContext.js';
import { Route, Redirect } from 'react-router-dom';
import TitledRoute from './TitledRoute.jsx';

function NotAuthRoute({ disableTitle, component: Component, ...rest }) {
	const { session } = useSession();
	const SelectedRoute = (disableTitle)? Route: TitledRoute;
	if(!session.isAuthenticated){
		return (
			<SelectedRoute
				{...rest}
				render={props => (<Component {...props} />)}
			/>
		);
	}
	return (<Redirect to='/' />);
};
NotAuthRoute.propTypes = {
	disableTitle: PropTypes.bool,
	component: PropTypes.any.isRequired,
};
NotAuthRoute.defaultProps = { disableTitle: false };

export default NotAuthRoute;