import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import emptyStateImage from '../../assets/empty-state.svg';
import { Button } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	contentEmpty: {
		padding: theme.spacing(4, 4, 4, 4),
		paddingBottom: theme.spacing(4)+'px !important',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		userSelect: 'none',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(8, 8, 8, 8),
			paddingBottom: theme.spacing(8)+'px !important',
		},
	},
	contentEmptyImage: {
		width: '100%',
		maxWidth: 250,
	},
	contentEmptyText: {
		maxWidth: 300,
		textAlign: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	}
}));

export default function HomeEmptyCard(){
	const classes = useStyles();
	const history = useHistory();

    const handleClick = () => history.push('/planifications');

    return (
        <Card>
            <CardContent className={classes.contentEmpty}>
                <img className={classes.contentEmptyImage} src={emptyStateImage} draggable="false" alt="No hay información" />
                <Typography className={classes.contentEmptyText} color="primary">No hay planificaciones programadas!</Typography>
                <Button variant="contained" color="primary" disableElevation onClick={handleClick}>Ir a Planificaciones</Button>
            </CardContent>
        </Card>
    );
};