import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

function DialogCrearSimulacion(props){
	const handleDialogClose = (closeState) => {
		if(closeState == 'agree'){
			props.onAgree();
		}
		else{
			props.onCancel();
		}
	};

	return (
		<Dialog
			open={props.open}
			onClose={() => handleDialogClose('cancel')}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Iniciar una Simulaci&oacute;n</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Atenci&oacute;n! La simulaci&oacute;n de una planificacion puede tardar varios minutos
					en realizarse.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleDialogClose('disagree')} color="primary">
					Cancelar
				</Button>
				<Button onClick={() => handleDialogClose('agree')} color="primary" autoFocus>
					Continuar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

DialogCrearSimulacion.propTypes = {
	open: PropTypes.bool.isRequired,
	onAgree: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default DialogCrearSimulacion;