import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";
import { useSocket } from '../../contexts/WebSocketContext.js';
import { useSession } from '../../contexts/SessionContext.js';
import useStyles from './List.styles.js';
import { useFetch } from '../../hooks/fetch.js';

import moment from '../../utils/moment.js';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import DialogSelectPlanification from '../../components/DialogSelectPlanification';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { DropzoneDialog } from 'material-ui-dropzone'
import ListTableRow from '../../components/Planification/ListTableRow.jsx';

function findPlanificationInDay(id, day){
	if(day.parent.id == id)
		return day.parent;
	else {
		if(day.children && day.children instanceof Array){
			for(let planification of day.children){
				if(planification.id == id) return planification;
			}
		}
	}
	return null;
};

function Planificaciones()
{
	const classes = useStyles();
	const history = useHistory();
	const { api } = useSession();
	const { enqueueSnackbar } = useSnackbar();
	const socket = useSocket();

	const [days, setDays] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({ limit: 10, page: 0 });
	const [openRow, setOpenRow] = useState(0);
	const [createNew, setCreateNew] = useState(true);
	const [dialog, setDialog] = useState({
		open: false,
		day: null,
		selected: null,
	});

	const [dialogUploadOpen, setDialogUploadOpen] = useState(false);

	const planifications = useFetch('/planifications', {
		parser(data){
			// Break if invalid data!
			if(!data || !(data instanceof Array)) throw new Error('Invalid Data!');

			// Continue
			if(data.length == 0) return;
			
			let index = new Map;
			for(let day of data){
				// Days span
				day.date_start = moment(day.date_start).format('LL');
				day.date_end = moment(day.date_end).format('LL');

				// Parent Dates
				let parent = day.parent;
				parent.start_at = moment(parent.start_date).format('LT');
				parent.end_at = moment(parent.end_date).format('lll');

				index.set(parent.id, parent);

				// Children Dates and State
				for(let planification of day.children){
					planification.start_at = moment(planification.start_date).format('LT');
					planification.end_at = moment(planification.end_date).format('lll');
					index.set(planification.id, planification);
				}
			}
			
			return {
				list: data,
				index,
			};
		}
	});

	useEffect(() => {
		if(planifications.status != 'done' || !planifications?.data) return () => {};

		// Check Last Planification
		let { list } = planifications.data;
		let lastDay = list[0];
		let mainPlanification = (lastDay.selected_id)? findPlanificationInDay(lastDay.selected_id, lastDay): lastDay.parent;

		// Parse dates
		let now = new Date;
		mainPlanification.start_date = new Date(mainPlanification.start_date);
		mainPlanification.end_date = new Date(mainPlanification.end_date);
		
		// Check if in range
		setCreateNew(!(mainPlanification.start_date <= now && mainPlanification.end_date >= now));
		setDays(list);
		setLoading(false);

	}, [planifications.status]);

	useEffect(() => {
		if(socket && socket.instance != null) socket.on('message', handleSocketMessage);
		return () => {
			if(socket && socket.instance != null) socket.off('message', handleSocketMessage);
		};
	}, []);

	const handleRealoadStates = () => {
		setDays((oldData) => {
			if(oldData != null){
				return oldData.slice(0, oldData.length);
			}
			return oldData;
		});
	};

	const handleChangeRowsPerPage = (event) => {
		setOpenRow(null);
		setPagination({
			limit: (+event.target.value),
			page: 0,
		});
	};

	const handleChangePage = (event, newPage) => {
		setOpenRow(null);
		setPagination({
			...pagination,
			page: newPage,
		});
	};

	const handleOpenRow = (rowIndex) => {
		if(openRow == rowIndex){
			setOpenRow(null);
		}
		else{
			setOpenRow(rowIndex);
		}
	};

	const handleSelectPlanification = async (day, selectedPlanification) => {
		// Set to false
		for(let planification of day.children) planification.selected = false;
		day.parent.selected = false;

		// Change state in backend
		try{
			let result = await api.post('/set-selected', { id: selectedPlanification.id });
			if(result){
				selectedPlanification.selected = true;
				day.selected_id = selectedPlanification.id;
				// Update State
				setDays(days.splice(0, days.length));
			}
		}
		catch(err){
			//console.error(err);
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	const handleDialogAgree = async () => {
		await handleSelectPlanification(dialog.day, dialog.selected);
		setDialog({
			open: false,
			day: null,
			selected: null,
		});
	};

	const handleDialogCancel = () => {
		setDialog({
			open: false,
			day: null,
			selected: null,
		});
	};

	const handleSelect = async (index, id) => {
		let day = days[index];
		let plan = findPlanificationInDay(id, day);
		if(!plan) return;

		// Check 'job_state'
		if(plan.job_state == 'submitted' || plan.job_state == 'pending' || plan.job_state == 'runnable' || plan.job_state == 'starting' || plan.job_state == 'running'){
			enqueueSnackbar('La planificación se está simulando!', { variant: 'error' });
			return;
		}
		else if(plan.job_state == 'failed'){
			enqueueSnackbar('La planificación no se simuló correctamente!', { variant: 'error' });
			return;
		}

		if(plan.alerts > 0){
			setDialog({
				open: true,
				day,
				selected: plan,
			});
		}
		else{
			handleSelectPlanification(day, plan);
		}
	};

	const handleSocketMessage = event => {
		try{
			let result = JSON.parse(event.data);
			if(!('data' in result)) throw new Error('Property "data" not found is message!');

			let changes = false;
			for(let plan of result.data){
				// Check
				let planification = planifications.data.index.get(plan.id);
				if(!planification) continue;
				// Update data
				if(plan.job_state != planification.job_state){
					//console.debug(plan.job_state, ' => ', planification.job_state, plan);
					Object.assign(planification, plan);
					changes = true;
				}
			}

			// Update State
			if(changes && days.length > 0) setDays(days.splice(0, days.length));
		}
		catch(err){
			//console.error(err);
		}
	};

	const handleFileSave = (files, event) => {
		setDialogUploadOpen(false);
		if(files.length == 1){
			let file = files.pop();
			let form = new FormData();
			form.append('file', file);

			// .then(result => { console.log(result); })
			api.post('/planification/file/upload', form).catch(err => {
				enqueueSnackbar(err.message, { variant: 'error' });
			});
		}
	};

	if(loading == false && days != null){
		return (
			<section className={classes.root}>
				{(createNew)&&(
					<>
						<Alert
							action={
								<>
									<Button
										className={classes.btnActionLeft}
										color="primary"
										variant="contained"
										disableElevation
										size="small"
										onClick={() => setDialogUploadOpen(true)}
									>
										Subir Plantilla
									</Button>
									{/* <Button
										color="primary"
										variant="contained"
										disableElevation
										size="small"
										onClick={() => history.push('/planification/create')}
									>
										Crear Planificaci&oacute;n
									</Button> */}
								</>
							}
							severity="info"
							className={classes.infoAlert}
						>
							No hay planificaciones para hoy!
						</Alert>
						<DropzoneDialog
							open={dialogUploadOpen}
							dialogTitle="Subir Archivo"
							dropzoneText={"Tipo de archivos: Excel (xls, xlsx) - Tamaño máximo: 10Mb"}
							dropzoneClass={classes.dropzoneContainer}
							dropzoneParagraphClass={classes.dropzoneParagraph}
							cancelButtonText="Cancelar"
							submitButtonText="Subir"
							onSave={handleFileSave}
							filesLimit={1}
							acceptedFiles={['.xlsx', '.xls']}
							showAlerts={['error']}
							showPreviews={true}
							showPreviewsInDropzone={false}
							useChipsForPreview
							previewText="Archivo:"
							maxFileSize={10000000}
							onClose={() => setDialogUploadOpen(false)}
						/>
					</>
				)}
				<Paper className={classes.tableRoot}>
					<TablePagination
						rowsPerPageOptions={[10, 20, 50]}
						labelRowsPerPage="Planificaciones por página:"
						component="div"
						count={days.length}
						rowsPerPage={pagination.limit}
						page={pagination.page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
					<Divider />
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell className={classes.tableHeaderCellFirst}></TableCell>
								<TableCell className={classes.tableHeaderCell} style={{ minWidth: 170 }}>Fecha</TableCell>
								<TableCell className={classes.tableHeaderCell} style={{ minWidth: 75 }}>Cantidad Planificaciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{days.slice(pagination.page * pagination.limit, pagination.page * pagination.limit + pagination.limit).map((planification, index) => (
								<ListTableRow
									key={index}
									index={index}
									date={planification.date_start}
									parent={planification.parent}
									planifications={planification.children}
									selected={planification.selected_id}
									open={openRow}
									onReload={handleRealoadStates}
									onOpen={() => handleOpenRow(index)}
									onSelect={(id) => handleSelect(index, id)}
								/>
							))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={[10, 20, 50]}
						labelRowsPerPage="Planificaciones por página:"
						component="div"
						count={days.length}
						rowsPerPage={pagination.limit}
						page={pagination.page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
				<DialogSelectPlanification open={dialog.open} onAgree={handleDialogAgree} onCancel={handleDialogCancel} />
			</section>
		);
	}

	return (
		<section className={classes.root}>
			<Card>
				<CardContent className={classes.loading}>
					<CircularProgress />
					<div>Cargando Planificaciones...</div>
				</CardContent>
			</Card>
		</section>
	);
};

Planificaciones.propTypes = {};

export default Planificaciones;