import { makeStyles } from '@material-ui/core/styles';

// import grey from '@material-ui/core/colors/grey';
// import green from '@material-ui/core/colors/green';
// import yellow from '@material-ui/core/colors/yellow';
// import red from '@material-ui/core/colors/red';

export default makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	viewerCard: {
		marginBottom: theme.spacing(2),
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(1),
	},
	buttonItem: {
		marginRight: theme.spacing(0.5),
	},
	buttonItemRight: {
		marginLeft: 'auto',
	},
	dialogContainer: {
	  display: 'flex',
	  flexWrap: 'wrap',
	},
	dialogFormControl: {
	  margin: theme.spacing(1),
	  minWidth: 120,
	},
}));