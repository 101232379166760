import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import './Grid.scss';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BackspaceIcon from '@material-ui/icons/Backspace';
import WrapTextIcon from '@material-ui/icons/WrapText';

import red from '@material-ui/core/colors/red';

import { fieldLimits, preventDefault } from './utils.js';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            '--primary-color': theme.palette.primary.main,
            '--error-color': red[100],
            '--error-text-color': red[500],
            backgroundColor: '#f3f3f3',
            height: '100%',
        },
    };
});

const t1hr = 3600000;

function Grid({ startDate, data, disabled, onChange, onLeave, onFocus, onAddRow, onRemove, onCopy })
{
    const classes = useStyles();

    const generateTime = (index) => {
        let timestamp = startDate.getTime() + (index * t1hr);
        let newDate = new Date(timestamp);
        let hour = ('00' + newDate.getHours()).substr(-2);
        return hour + ':00';
    };

    const onKeyDown = (event, rowIndex, colIndex) => {
        let lastRowIndex = data.length - 1;
        let lastColIndex = data[0].length - 1;

        if(event.key == 'ArrowUp' && event.ctrlKey){
            event.preventDefault();
            if(rowIndex > 0){
                let elem = document.getElementById(`input-${rowIndex - 1}-${colIndex}`);
                if(elem) elem.focus();
            }
        }
        else if(event.key == 'ArrowDown' && event.ctrlKey){
            event.preventDefault();
            if(rowIndex < lastRowIndex){
                let elem = document.getElementById(`input-${rowIndex + 1}-${colIndex}`);
                if(elem) elem.focus();
            }
        }
        else if(event.key == 'ArrowLeft' && event.ctrlKey){
            event.preventDefault();
            if(colIndex > 0){
                let elem = document.getElementById(`input-${rowIndex}-${colIndex - 1}`);
                if(elem) elem.focus();
            }
            else if(colIndex == 0 && rowIndex > 0){
                let elem = document.getElementById(`input-${rowIndex - 1}-${lastColIndex}`);
                if(elem) elem.focus();
            }
        }
        else if(event.key == 'ArrowRight' && event.ctrlKey){
            event.preventDefault();
            if(colIndex < lastColIndex){
                let elem = document.getElementById(`input-${rowIndex}-${colIndex + 1}`);
                if(elem) elem.focus();
            }
            else if(colIndex == lastColIndex && rowIndex < lastRowIndex){
                let elem = document.getElementById(`input-${rowIndex + 1}-0`);
                if(elem) elem.focus();
            }
        }
    };

    return (
        <div className={classes.root}>
            <table className="grid-table-root" cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th width={100}>Fecha</th>
                        <th>
                            <span className="field">Carb&oacute;n</span>
                            <IconButton size="small">
                                <Tooltip title={
                                    <React.Fragment>
                                        <Typography color="inherit">wi_301</Typography>
                                        M&iacute;nimo: {fieldLimits.coal[0]} / M&aacute;ximo: {fieldLimits.coal[1]}
                                    </React.Fragment>
                                }>
                                    <InfoOutlinedIcon size="small" />
                                </Tooltip>
                            </IconButton>
                        </th>
                        <th>
                            <span className="field">Petr&oacute;leo</span>
                            <IconButton size="small">
                                <Tooltip title={
                                    <React.Fragment>
                                        <Typography color="inherit">Petr&oacute;leo</Typography>
                                        M&iacute;nimo: {fieldLimits.oil[0]} / M&aacute;ximo: {fieldLimits.oil[1]}
                                    </React.Fragment>
                                }>
                                    <InfoOutlinedIcon size="small" />
                                </Tooltip>
                            </IconButton>
                        </th>
                        <th>
                            <span className="field">Cal Hidratada</span>
                            <IconButton size="small">
                                <Tooltip title={
                                    <React.Fragment>
                                        <Typography color="inherit">Ca(OH)<sub>2</sub></Typography>
                                        M&iacute;nimo: {fieldLimits.caoh_dos[0]} / M&aacute;ximo: {fieldLimits.caoh_dos[1]}
                                    </React.Fragment>
                                }>
                                    <InfoOutlinedIcon size="small" />
                                </Tooltip>
                            </IconButton>
                        </th>
                        <th>
                            <span className="field">Temperatura</span>
                            <IconButton size="small">
                                <Tooltip title={
                                    <React.Fragment>
                                        <Typography color="inherit">ti_328</Typography>
                                        M&iacute;nimo: {fieldLimits.temperature[0]} / M&aacute;ximo: {fieldLimits.temperature[1]}
                                    </React.Fragment>
                                }>
                                    <InfoOutlinedIcon size="small" />
                                </Tooltip>
                            </IconButton>
                        </th>
                        <th>
                            <span className="field">Ritmo</span>
                            <IconButton size="small">
                                <Tooltip title={
                                    <React.Fragment>
                                        <Typography color="inherit">wi_182</Typography>
                                        <Typography color="inherit" variant="body2">Ritmo de alimentaci&oacute;n</Typography>
                                        M&iacute;nimo: {fieldLimits.rate[0]} / M&aacute;ximo: {fieldLimits.rate[1]}
                                    </React.Fragment>
                                }>
                                    <InfoOutlinedIcon size="small" />
                                </Tooltip>
                            </IconButton>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{ generateTime(rowIndex) }</td>
                            {row.map((data, colIndex) => (
                                <td key={colIndex}>
                                    <input
                                        id={`input-${rowIndex}-${colIndex}`}
                                        type="number"
                                        className={clsx(data.error && 'state-alert')}
                                        min="0"
                                        value={data.value}
                                        onFocus={(event) => onFocus(data, rowIndex, colIndex)}
                                        onKeyDown={(event) => onKeyDown(event, rowIndex, colIndex)}
                                        onChange={(event) => onChange(event, data)}
                                        onBlur={(event) => onLeave(event, data)}
                                        disabled={disabled}
                                    />
                                </td>
                            ))}
                            <td className="action-remove-row">
                                <div className="action-remove-row-content">
                                    <IconButton size="small" onMouseDown={preventDefault} onClick={() => onRemove(rowIndex)}>
                                        <Tooltip title={<>Eliminar Fila</>}>
                                            <BackspaceIcon fontSize="small" />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton size="small" onMouseDown={preventDefault} onClick={() => onCopy(rowIndex)}>
                                        <Tooltip title={<>Copiar Fila Abajo</>}>
                                            <WrapTextIcon fontSize="small" />
                                        </Tooltip>
                                    </IconButton>
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr className="last-row" onClick={() => onAddRow(1)}>
                        <td>
                            <AddIcon fontSize="small" />
                        </td>
                        <td>
                            <AddIcon fontSize="small" />
                        </td>
                        <td>
                            <AddIcon fontSize="small" />
                        </td>
                        <td>
                            <AddIcon fontSize="small" />
                        </td>
                        <td>
                            <AddIcon fontSize="small" />
                        </td>
                        <td>
                            <AddIcon fontSize="small" />
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

Grid.propTypes = {
    onFocus: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onLeave: PropTypes.func.isRequired,
    onAddRow: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    startDate: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default Grid;