import { useState, useEffect } from 'react';
import { useSession } from '../contexts/SessionContext.js';

const cacheStorage = new Map;

export const useFetchDoneEffect = (callback, resultObject) => {
	useEffect(() => {
		if(resultObject.status == 'done') callback(resultObject.data);
	}, [resultObject.status]);
}

export const useFetch = (urlPath, { cache = false, parser = null } = { cache: false, parser: null }) => {
	const { api } = useSession();

	const [status, setStatus] = useState('idle');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
		// Verify URL
        if (!urlPath) return () => {};

		// Check cache
		if (cache == true && cacheStorage.has(urlPath)) {
			const data = cacheStorage.get(urlPath);
			setData(data);
			setStatus('done');
			return () => {};
		}

		// Fetch data if its new
		const fetchData = async () => {
            setStatus('loading');
            let data = await api.get(urlPath);
			if(cache == true) cacheStorage.set(urlPath, data);
            if(typeof(parser) == 'function') data = parser(data);
			setData(data);
            setStatus('done');
        };

        fetchData().catch(err => {
			setError(err);
			setStatus('error');
		});
    }, [urlPath]);

    return { status, data, error };
};