import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const CustomInput = withStyles((theme) => ({
    root: {
        svg: {
            color: '#CCCCCC',
        }
    },
    input: {
        borderRadius: 2,
        position: 'relative',
        backgroundColor: '#222222',
        color: '#CCCCCC',
        width: 40,
        border: 0,
        fontSize: 16,
        paddingTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        '&:focus': {
            borderRadius: 2,
            border: 0,
            backgroundColor: '#222222',
            color: '#CCCCCC',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    select: {
        marginRight: theme.spacing(1),
    },
    iconStyle: {
        display: 'inline-block',
        position: 'absolute',
        top: 0,
        right: 0,
        pointerEvents: 'none',
        color: '#CCC',
    }
}));

function CustomSelect({ value, onChange, disabled=false }){
    const classes = useStyles();
    return (
        <FormControl>
            <Select
                className={classes.select}
                disabled={disabled}
                size="small"
                value={value}
                onChange={onChange}
                input={<CustomInput />}
                IconComponent={() => (
                    <KeyboardArrowDownIcon className={classes.iconStyle} />
                )}
            >
                <MenuItem value="mp">MP</MenuItem>
                <MenuItem value="no">NOX</MenuItem>
                <MenuItem value="so">SO2</MenuItem>
            </Select>
        </FormControl>
    );
};

CustomSelect.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};


export default CustomSelect;