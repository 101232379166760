import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import './TimelapseViewer.scss';
import { CircularProgress } from '@material-ui/core';
import { useInterval } from '../../hooks/interval.js';


export default function TimelapseViewer({ loading, frames, framesLoading, currentFrame, onFrameChange, isInstantSelected }){
    const frame = frames[currentFrame];
    const lastFrameIndex = frames.length - 1;
    const [hoverVisible, setHoverVisible] = React.useState(false);
    const [hoverValue, setHoverValue] = React.useState(0);
    const [sliderNode, setSilderNode] = React.useState(null);
    const [playing, setPlaying] = React.useState(false);

    useInterval(() => {
        if(playing){
            if(currentFrame < lastFrameIndex){
                onFrameChange(currentFrame + 1);
            }
            else if(currentFrame == lastFrameIndex){
                setPlaying(false);
            }
        }
    }, 500, [playing, frames, currentFrame, onFrameChange]);

    const onSliderChange = (event) => {
        let value = parseInt(event.target.value);
        onFrameChange(value);
    };

    const handleClickNext = () => {
        if(currentFrame < lastFrameIndex){
            onFrameChange(currentFrame + 1);
        }
    };

    const handleClickPrev = () => {
        if(currentFrame > 0){
            onFrameChange(currentFrame - 1);
        }
    };

    const handleClickPlay = () => {
        if(!playing && currentFrame < lastFrameIndex){
            setPlaying(true);
        }
        else if(playing){
            setPlaying(false);
        }
    };

    const handleMouseEnter = event => {
        if(sliderNode != null){
            var bounds = event.target.getBoundingClientRect();
            var offset = event.clientX - bounds.left;
            let percent = Math.round((offset / bounds.width) * lastFrameIndex);
            setHoverValue(percent);
            setHoverVisible(true);
        }
    };

    const handleMouseLeave = event => {
        if(sliderNode != null){
            setHoverValue(0);
            setHoverVisible(false);
        }
    };

    const handleMouseMove = event => {
        if(sliderNode != null){
            var bounds = event.target.getBoundingClientRect();
            var offset = event.clientX - bounds.left;
            let percent = Math.round((offset / bounds.width) * lastFrameIndex);
            setHoverValue(percent);
            setHoverVisible(true);
        }
    };

    const sliderMount = React.useCallback((node) => setSilderNode(node), []);

    React.useEffect(() => {
        if(sliderNode != null){
            sliderNode.addEventListener('mouseenter', handleMouseEnter);
            sliderNode.addEventListener('mouseleave', handleMouseLeave);
            sliderNode.addEventListener('mousemove', handleMouseMove);
            return () => {
                sliderNode.removeEventListener('mouseenter', handleMouseEnter);
                sliderNode.removeEventListener('mouseleave', handleMouseLeave);
                sliderNode.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [sliderNode]);

    const handleKeyDown = (event) => {
        if(loading == false && framesLoading == false && frames.length > 0){
            if(event.code == 'ArrowLeft' && currentFrame > 0){
                onFrameChange(currentFrame - 1);
            }
            else if(event.code == 'ArrowRight' && currentFrame < lastFrameIndex){
                onFrameChange(currentFrame + 1);
            }
            else if(event.code == 'KeyK'){
                handleClickPlay();
            }
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    return (
        <div className="timelapse-viewer">
            <div className="viewer-frame">
                {(loading || framesLoading) ?(
                    <div className="viewer-loading">
                        <div className="viewer-loading-content">
                            <CircularProgress />
                            <span className="text">{framesLoading?'Cargando Imagenes...':'Cargando'}</span>
                        </div>
                    </div>
                ):((frames.length == 0) ?(
                    <div className="viewer-loading">
                        <div className="viewer-loading-content">
                            <span className="text">{ isInstantSelected? 'No se pudieron cargar las imagenes!': 'Selecciona un instante de tiempo.' }</span>
                        </div>
                    </div>
                ):(
                    <>
                        <div className="viewer-image-container-outer">
                            <div className="viewer-image-container-inner">
                                {frame.loading?(
                                    <div className="viewer-image-container-inner-message">
                                        <span>Cargando Imagen ({currentFrame + 1}/{frames.length})...</span>
                                    </div>
                                ):(frame.error?(
                                    <div className="viewer-image-container-inner-message">
                                        <span className="error-icon">
                                            <ErrorOutlineIcon fontSize="large" />
                                        </span>
                                        <span>No se pudo cargar la imagen!</span>
                                    </div>
                                ):(
                                    <div className="viewer-image-container-inner-image">
                                        <img src={frame.instance.src} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="viewer-toolbar">
                            <div className="viewer-toolbar-progress">
                                <div className="progress-container">
                                    <div className="progress-bar">
                                        <progress
                                            className={clsx("progress-bar-hover", hoverVisible && "visible")}
                                            value={hoverValue}
                                            max={lastFrameIndex}
                                        />
                                        <progress
                                            className="progress-bar-progress"
                                            value={currentFrame}
                                            min={0}
                                            max={lastFrameIndex}
                                        />
                                        <input
                                            type="range"
                                            ref={sliderMount}
                                            value={currentFrame}
                                            min={0}
                                            max={lastFrameIndex}
                                            onChange={onSliderChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="viewer-toolbar-info">
                                <div className="viewer-toolbar-box">
                                    <div className="viewer-toolbar-step">{ currentFrame + 1 }/{ frames.length }</div>
                                </div>
                                <nav className="viewer-toolbar-nav">
                                    <button className="viewer-toolbar-button" disabled={currentFrame == 0} onClick={handleClickPrev}>
                                        <ArrowBackIcon />
                                    </button>
                                    <button className="viewer-toolbar-button" onClick={handleClickPlay}>
                                        {playing?(<PauseIcon />):(<PlayArrowIcon />)}
                                    </button>
                                    <button className="viewer-toolbar-button" disabled={currentFrame == lastFrameIndex} onClick={handleClickNext}>
                                        <ArrowForwardIcon />
                                    </button>
                                </nav>
                                <div className="viewer-toolbar-box">
                                    <div className="viewer-toolbar-time">{ frame.time }</div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
            {/* <div className="viewer-thumbs">
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
                <div className="thumb">Thumbnail</div>
            </div> */}
        </div>
    );
};

TimelapseViewer.propTypes = {
    loading: PropTypes.bool.isRequired,
    framesLoading: PropTypes.bool.isRequired,
    frames: PropTypes.array.isRequired,
    currentFrame: PropTypes.number.isRequired,
    onFrameChange: PropTypes.func.isRequired,
    isInstantSelected:  PropTypes.bool.isRequired,
};
TimelapseViewer.defaultProps = {
    loading: false,
    framesLoading: false,
};
